import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { Button, Form } from 'semantic-ui-react';
import { FormField, PasswordValidationHelper } from 'components';
import passwordValidation from './passwordValidation';

const ChangePasswordForm = ({ onSubmit }) => {
  const { formatMessage } = useIntl();
  const [password, setPassword] = useState('');

  return (
    <Form onSubmit={onSubmit} style={{ maxWidth: '400px', margin: '20px' }}>
      <PasswordValidationHelper password={password} />
      <FormField
        required
        id="currentPassword"
        type="password"
        label={formatMessage({ id: 'page.user.changePassword.form.label.currentPassword' })}
        element={Form.Input}
      />
      <FormField
        required
        id="password"
        type="password"
        label={formatMessage({ id: 'page.user.changePassword.form.label.newPassword' })}
        element={Form.Input}
        onChange={(event, value) => setPassword(value)}
      />
      <FormField
        required
        id="repeatPassword"
        type="password"
        label={formatMessage({ id: 'page.user.changePassword.form.label.repeatPassword' })}
        element={Form.Input}
        onChange={(event, value) => setPassword(value)}
      />
      <Button type="submit" color="green" size="large">
        <FormattedMessage id="general.submit" />
      </Button>
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'changePassword', validate: passwordValidation })(ChangePasswordForm);
