import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { authenticate } from 'redux/modules/auth';
import { FormattedMessage } from 'react-intl';
import { Button, Image } from 'semantic-ui-react';

export class SingleSignOn extends PureComponent {
  static propTypes = {
    authenticate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    tenantId: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { location } = this.props;
    // Handle authentication callback. Client should've returned with code when authentication was okay
    if (location.query && 'code' in location.query) {
      this.handleAuthentication(location.query.code);
    }
  }

  handleAuthentication(authCode) {
    const { authenticate, tenantId } = this.props;
    authenticate('google', tenantId, authCode);
  }

  render() {
    return (
      <div>
        {/*
          // TODO OWN-5: Hide Facebook SSO login before Gateway API is ready
          <Button color="facebook" style={{ minWidth: '160px' }} onClick={() => this.props.authenticate('facebook', this.props.tenant.id)}>
            <Icon name="facebook" />
            <FormattedMessage id="page.login.label.facebook" />
          </Button>
        */}
        <Button
          fluid
          style={{
            background: 'white',
            border: 'thin solid #888',
            borderRadius: '5px',
            boxShadow: '1px 1px 1px greg',
            color: '#444',
            display: 'inline-block',
            margin: '0 auto',
            maxWidth: '320px',
          }}
          onClick={() => this.handleAuthentication()}
        >
          <Image
            src="/google-24.png"
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          />
          <span
            style={{
              display: 'inline-block',
              paddingLeft: '42px',
              paddingRight: '42px',
              verticalAlign: 'middle',
            }}
          >
            <FormattedMessage id="page.login.label.google" />
          </span>
        </Button>
      </div>
    );
  }
}

export default connect(state => ({ location: state.router.location, tenantId: state.tenant.id }), { authenticate })(SingleSignOn);
