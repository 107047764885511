import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Divider } from 'semantic-ui-react';

export default class Footer extends Component {
  render() {
    return (
      <Container textAlign="center" style={{ color: 'grey', margin: '10px 20px', fontSize: '0.9em', width: '100%' }}>
        <Divider />
        <span>
          <FormattedMessage id="footer.message" />
        </span>
      </Container>
    );
  }
}
