import PropTypes from 'prop-types';
import React from 'react';
import { Image } from 'semantic-ui-react';

const Logo = props => {
  const { tenant, inverted, src, style } = props;
  const defaultSrc = inverted ? '/logo.png' : '/splashscreen1x.png';
  const imgSrc = src || defaultSrc;
  const alt = {
    kiniseko: 'Ki Niseko logo',
    nisekocentral: 'Niseko Central logo',
    skye: 'Skye Niseko logo',
  };

  // Return null if there is no tenant matched
  if (!Object.keys(alt).includes(tenant)) {
    return null;
  }

  return <Image src={imgSrc} alt={alt[tenant]} style={style} />;
};

Logo.propTypes = {
  tenant: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  src: PropTypes.string,
  style: PropTypes.object,
};

export default Logo;
