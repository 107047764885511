import types from './types';

const { LOAD, LOAD_SUCCESS, LOAD_FAIL } = types;

export default function requestPayment(userId, data, token) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    notifications: [null, 'notifications.requestPayment.successful', null],
    promise: client => client.post(`/owners/${userId}/requestPayment`, { data, token }),
  };
}
