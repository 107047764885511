import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { Button, Form, Header } from 'semantic-ui-react';
import { FormField, PasswordValidationHelper } from 'components';
import passwordValidation from './passwordValidation';

export class ResetPasswordForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  state = {
    password: '',
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { password } = this.state;
    return (
      <Form size="large" className="attached segment" onSubmit={this.props.onSubmit} style={{ padding: '20px' }}>
        <Header>
          <FormattedMessage id="page.resetPassword.form.title" />
        </Header>
        <PasswordValidationHelper password={password} />
        <FormField
          required
          id="password"
          type="password"
          label={formatMessage({ id: 'page.resetPassword.form.label.password' })}
          element={Form.Input}
          onChange={(event, value) => this.setState({ password: value })}
        />
        <FormField
          required
          id="repeatPassword"
          type="password"
          label={formatMessage({ id: 'page.resetPassword.form.label.retypePassword' })}
          element={Form.Input}
        />
        <Button type="submit" color="green" size="large" disabled={(!password || password.length < 8)}>
          <FormattedMessage id="general.submit" />
        </Button>
      </Form>
    );
  }
}

const withIntl = injectIntl(ResetPasswordForm);

export default reduxForm({ form: 'resetPassword', validate: passwordValidation })(withIntl);
