import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, Input, Message, Table, TextArea } from 'semantic-ui-react';

import obfuscate from 'utils/strings/obfuscate';
import ValidationStatus from './ValidationStatus';

export const FIELD_TYPES = {
  inputText: 'inputText',
  dropdownList: 'dropdownList',
  status: 'status',
  textArea: 'textArea',
};

const EntityDetailsFormField = ({
  children,
  hideEmpty,
  notes,
  noteSymbol,
  obfuscated,
  options,
  readOnly,
  required,
  translationId,
  type,
  validation,
  value,
  onBlur,
  onClick,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  const handleBlur = () => {
    if (typeof onBlur === 'function') {
      onBlur();
    }
  };

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const handleChange = (event, eventData) => {
    if (typeof onChange === 'function') {
      onChange(eventData.value);
    }
  };

  const isDropdownList = type === FIELD_TYPES.dropdownList && Array.isArray(options);

  // Render the whole form
  return (
    <>
      <Table.Row>
        {/* Label Column */}
        <Table.Cell style={{ width: '250px', fontWeight: 'bold' }}>
          {formatMessage({ id: translationId })}
          {required && !value && (
            <span style={{ color: 'red' }}>
              *
            </span>
          )}
          {noteSymbol && (
            <span style={{ color: 'red' }}>
              {noteSymbol}
            </span>
          )}
        </Table.Cell>
        {/* Value Column */}
        <Table.Cell style={{ display: 'flex', flexDirection: 'column' }}>
          {/* Render input component or field data */}
          <div className="log-hidden-element">
            {
              (type === FIELD_TYPES.status) && (
                <>
                  <ValidationStatus
                    failed={!value}
                    success={!!value}
                  />
                  {formatMessage({ id: value ? 'general.verified' : 'general.unverified' })}
                </>
              )
            }
            {
              isDropdownList && readOnly && (
                options?.find(opt => opt.value === value)?.text
              )
            }
            {
              isDropdownList && !readOnly && (options.length > 0 || !hideEmpty) && (
                <Dropdown
                  options={options}
                  value={value}
                  fluid
                  selection
                  onChange={handleChange}
                />
              )
            }
            {
              !isDropdownList && readOnly && (
                obfuscated ? obfuscate(value) : value
              )
            }
            {
              (type === FIELD_TYPES.inputText) && !readOnly && (
                <Input
                  defaultValue={value}
                  style={{ minWidth: '250px' }}
                  onBlur={handleBlur}
                  onClick={handleClick}
                  onChange={handleChange}
                />
              )
            }
            {
              (type === FIELD_TYPES.textArea) && !readOnly && (
                <TextArea
                  value={value}
                  onClick={handleClick}
                  onChange={handleChange}
                />
              )
            }
            {/* Show field validation status if available */}
            {
              !readOnly && validation && (
                <ValidationStatus
                  failed={validation.failed === true}
                  loading={validation.loading === true}
                  success={validation.success === true}
                />
              )
            }
          </div>
          {
            children && <div>{children}</div>
          }
        </Table.Cell>
      </Table.Row>
      {/* Field notes (optional) */}
      {Array.isArray(notes) && notes.length > 0 && (
        <Table.Cell colSpan={2}>
          <Message
            /* Info is not supported when it is used in a form. As there is no info state in a form. Styled the same as info */
            style={{ backgroundColor: '#f8ffff', color: '#276f86', boxShadow: '0 0 0 1px #a9d5de inset, 0 0 0 0 transparent' }}
            list={notes}
          />
        </Table.Cell>
      )}
    </>
  );
};

EntityDetailsFormField.propTypes = {
  children: PropTypes.node,
  hideEmpty: PropTypes.bool,
  noteSymbol: PropTypes.string,
  notes: PropTypes.arrayOf(PropTypes.string),
  obfuscated: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string,
  })),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  translationId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(FIELD_TYPES)).isRequired,
  validation: PropTypes.shape({
    failed: PropTypes.bool,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    validator: PropTypes.func,
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

export default EntityDetailsFormField;
