const valuesToSanitize = [
  'password',
  'address',
  'phone',
  'firstName',
  'name',
  'surName',
  'company',
  'address1',
  'address2',
  'address3',
  'address6',
  'phone',
  'mobile',
  'fax',
  'contactPhone',
  'contactMobile',
];

const propertiesToSanitize = ['user'];

const customSanitizer = request => {
  if (!request?.body) {
    return request;
  }

  let newBody;

  try {
    newBody = JSON.parse(request.body);
  }
  catch (error) {
    return request;
  }

  valuesToSanitize.forEach(value => {
    if (newBody[value]) {
      newBody[value] = '***removed***';
    }
    propertiesToSanitize.forEach(prop => {
      if (newBody[prop] && newBody[prop][value]) {
        newBody[prop][value] = '***removed***';
      }
    });
  });

  request.body = newBody;
  return request;
};

const domClassSanitizer = ['log-hidden-element'];

const requestSanitizer = request => customSanitizer(request);

const responseSanitizer = request => customSanitizer(request);

export {
  requestSanitizer, responseSanitizer, domClassSanitizer
};
