import types from './types';

const { DISPLAY, HIDE } = types;

export function display(title, text, messageType) {
  return { type: DISPLAY, title, text, messageType };
}

export function hide() {
  return { type: HIDE };
}
