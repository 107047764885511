/*
  Global configurations, applies for all tenants
*/

const AUTH_2FA_RECIPIENT_TYPES = {
  MOBILE: 'MOBILE',
  FIXED_LINE: 'FIXED_LINE',
  FIXED_LINE_OR_MOBILE: 'FIXED_LINE_OR_MOBILE',
};

const AUTH_2FA_DELIVERY_METHODS = {
  SMS: 'sms',
  VOICE: 'voice',
};

module.exports = {
  name: 'HTM Owners Portal',
  host: process.env.HOST || 'localhost',
  port: process.env.PORT || 8880,
  api: {
    host: process.env.APIHOST || 'http://localhost:3335',
  },
  auth2FaRecipientTypes: AUTH_2FA_RECIPIENT_TYPES,
  auth2FaDeliveryMethods: AUTH_2FA_DELIVERY_METHODS,
};
