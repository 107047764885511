import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List } from 'semantic-ui-react';
import { Link } from 'components/Common';

export class ThemeList extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    displayIcon: PropTypes.bool,
    links: PropTypes.array.isRequired,
    size: PropTypes.string,
    primary: PropTypes.string.isRequired,
  };

  static defaultProps = {
    displayIcon: true,
    size: 'big',
  };

  render() {
    const { color, displayIcon, links, size, primary } = this.props;
    return (
      <List divided relaxed="very" size={size}>
        {
          links.map(link => {
            const isAbsolute = link.url && link.url.startsWith('http');
            return (
              <List.Item key={link.url} style={{ color: (primary || color), marginBottom: '5px 0' }}>
                { displayIcon && <List.Icon name={link.icon || 'linkify'} size="large" verticalAlign="middle" style={{ padding: '5px' }} /> }
                <List.Content>
                  {
                    isAbsolute ? (
                      <List.Header as="a" target="_blank" href={link.url} style={{ margin: '0 5px' }}>
                        <span style={{ color: (primary || color) }}>{link.name}</span>
                      </List.Header>
                    ) : (
                      <List.Header as={Link} to={link.url} style={{ margin: '0 5px' }}>
                        <span style={{ color: (primary || color) }}>{link.name}</span>
                      </List.Header>
                    )
                  }
                  {
                    isAbsolute ? (
                      <List.Description as="a" target="_blank" href={link.url} style={{ margin: '0 5px' }}>{link.description}</List.Description>
                    ) : (
                      <List.Description as={Link} to={link.url} style={{ margin: '0 5px' }}>{link.description}</List.Description>
                    )
                  }
                </List.Content>
              </List.Item>
            );
          })
        }
      </List>
    );
  }
}

export default connect(state => ({ primary: state.tenant.theme.colors.primary }))(ThemeList);
