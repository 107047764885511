import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { clearStore as clearBookingStore, getRoomBookings, getRoomTypeStatistics } from 'redux/modules/booking';
import { clearStore as clearContractStore, loadActiveContracts } from 'redux/modules/contract';
import { groupRoomsByContracts, shouldDisplayForOccupancy } from 'utils/misc';
import { Dropdown, NoDataFound, ThemeHeader, RoomBooking, RoomTypeBooking, ContactUsWidget } from 'components';

import { tenants, SN_BLOCK_ID, NC_BLOCK_ID } from '../../constants';

const HIDDEN_ROOM_TYPES_TENANTS = [SN_BLOCK_ID, NC_BLOCK_ID];

export class Occupancy extends PureComponent {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    bookingStore: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      loaded: PropTypes.bool.isRequired,
      roomBookings: PropTypes.array.isRequired,
      error: PropTypes.object.isRequired,
    }),
    contractStore: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      loaded: PropTypes.bool.isRequired,
      contracts: PropTypes.array.isRequired,
      error: PropTypes.object.isRequired,
    }),
    clearBookingStore: PropTypes.func.isRequired,
    clearContractStore: PropTypes.func.isRequired,
    loadActiveContracts: PropTypes.func.isRequired,
    getRoomBookings: PropTypes.func.isRequired,
    getRoomTypeStatistics: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  state = {
    room: null,
  };

  componentDidMount() {
    const { user } = this.props.auth;
    this.props.loadActiveContracts(user.id);
  }

  componentDidUpdate(prevProps) {
    const prevLoaded = prevProps.contractStore.loaded;
    const { loaded, contracts } = this.props.contractStore;

    // Auto display the first valid room option after contracts loaded
    if (!prevLoaded && loaded && contracts.length > 0) {
      const filteredContracts = contracts.filter(c => shouldDisplayForOccupancy(c?.property));
      const firstValidContract = filteredContracts.find(c => c?.property?.code);
      this.handleChange(firstValidContract?.property?.code);
    }
  }

  componentWillUnmount() {
    // Clean up redux stores when unmounting the tab
    this.props.clearBookingStore();
    this.props.clearContractStore();
  }

  handleChange(code) {
    const { contracts } = this.props.contractStore;
    // Find the selected contract by room code
    const contract = code && contracts.find(c => c?.property?.code === code);
    if (contract) {
      // Update the state
      this.setState({ room: code });
      // Request the occupancy data based on the room type
      if (contract?.property?.isSoldAsRoomType === true) {
        this.props.getRoomTypeStatistics(contract.property.blockId);
      }
      else {
        this.props.getRoomBookings(contract.property.id, contract?.id);
      }
    }
  }

  renderNoBookings() {
    const { formatMessage } = this.props.intl;
    return (
      <NoDataFound
        iconColor="brown"
        iconName="folder open outline"
        message={formatMessage({ id: 'page.booking.label.noBookings' })}
      />
    );
  }

  renderFilter() {
    const { contracts } = this.props.contractStore;
    // Group rooms by Strata & RoomType & BlockId (for grouping the same tenant's RoomType's room together)
    const rooms = groupRoomsByContracts(contracts);

    const filteredRooms = rooms.filter(contract => shouldDisplayForOccupancy(contract?.property));

    // Render the options
    const options = filteredRooms.map(c => {
      const code = c?.property?.code;
      const blockId = c?.property?.blockId;
      const isRoomType = c?.property?.isSoldAsRoomType;
      const tenant = tenants[blockId].title || code; // Fallback to the room code

      return {
        text: isRoomType ? tenant : code, // Return the name of the tenant if it is a RoomType room
        key: code,
        value: code,
      };
    }).filter(o => o && o.key); // Filter out any property that was unabled to be matched
    const sortedOptions = orderBy(options, ['text'], ['asc']);
    // If there is more than one real room, then render the dropdown
    if (rooms.length > 1 && options.length > 1) {
      return (
        <Dropdown
          selection
          options={sortedOptions}
          value={this.state.room}
          labelId="page.booking.filter.label.room"
          onChange={value => this.handleChange(value)}
        />
      );
    }

    return null;
  }

  renderBookings() {
    const { loading, loaded, roomBookings, error } = this.props.bookingStore;
    const hasBookings = roomBookings.length > 0;
    const hasError = Object.keys(error).length > 0;

    // Display message when there is an error
    if (!loading && hasError) {
      return <NoDataFound />;
    }
    // Display message when there is no bookings found
    if (loaded && !hasBookings) {
      return this.renderNoBookings();
    }
    // Display bookings
    if (loaded && hasBookings) {
      // Hide Skye Niseko & Niseko Central room-types bookings
      const isAllowedRoomType = !HIDDEN_ROOM_TYPES_TENANTS.includes(roomBookings[0].id ?? 0);
      if (!isAllowedRoomType && !shouldDisplayForOccupancy(roomBookings[0].room)) {
        return this.renderNoBookings();
      }
      // Check if the data return is room type statistics,
      // default to true as there is no room object in the response
      const isRoomType = roomBookings[0].room?.isSoldAsRoomType ?? true;
      // Return RoomTypeBooking View if the selected room is a room type
      if (isRoomType) {
        return <RoomTypeBooking statistics={roomBookings[0]} />;
      }
      // Return RoomBooking View if the selected room is a room
      return <RoomBooking bookings={roomBookings} />;
    }
    return null;
  }

  render() {
    const { loading, loaded, contracts, error } = this.props.contractStore;
    const hasContracts = loaded && contracts.length > 0;
    const hasError = Object.keys(error).length > 0;
    // Find if there is at least one contract that includes property and possible to display
    const hasOccupancy = loaded && contracts.find(c => shouldDisplayForOccupancy(c?.property));
    return (
      <Container style={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
        <div>
          <ThemeHeader titleId="page.occupancy.label.statistics" />
          {/* Display message when there is no contracts found */}
          { !loading && (hasError || !hasContracts) && <NoDataFound />}
          {/* Display dropdown filer and bookings when there is data */}
          { loaded && hasOccupancy && this.renderFilter() }
          { loaded && hasOccupancy && this.renderBookings() }
          {/* Display message when there is no occupancy to display */}
          { loaded && hasContracts && !hasOccupancy && this.renderNoBookings() }
        </div>
        <ContactUsWidget />
      </Container>
    );
  }
}

const withIntl = injectIntl(Occupancy);

export default connect(
  state => (
    { auth: state.auth, contractStore: state.contract, bookingStore: state.booking }),
  { loadActiveContracts, clearBookingStore, clearContractStore, getRoomBookings, getRoomTypeStatistics }
)(withIntl);
