import { FIELD_TYPES } from '../../../components/Form/EntityDetailsForm';

export const BANK_ACCOUNT_TEMPLATES = [
  {
    orderNumber: 1,
    translationId: 'page.user.bankAccounts.accountOne',
  },
  {
    orderNumber: 2,
    translationId: 'page.user.bankAccounts.accountTwo',
  },
  {
    orderNumber: 3,
    translationId: 'page.user.bankAccounts.accountThree',
  },
];

// The Bank Account data fields as in the API model
export const BANK_ACCOUNT_FIELDS = [
  {
    name: 'accountName',
    translationId: 'form.label.beneficiary.accountName',
    required: true,
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'accountNumber',
    translationId: 'form.label.beneficiary.accountNumber',
    obfuscated: true,
    required: true,
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'bankName',
    translationId: 'form.label.beneficiary.bankName',
    required: true,
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'currency',
    translationId: 'form.label.currency',
    required: true,
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'branchName',
    translationId: 'form.label.beneficiary.branchName',
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'bankBranchAddress',
    translationId: 'form.label.beneficiary.bankAddress',
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'residenceCountry',
    translationId: 'form.label.beneficiary.currentCountryOfResidence',
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'fullName',
    translationId: 'form.label.beneficiary.fullName',
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'homeAddress',
    translationId: 'form.label.beneficiary.homeAddress',
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'swiftCode',
    translationId: 'form.label.swiftCode',
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'bsbCode',
    translationId: 'form.label.bsb',
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'ibanCode',
    translationId: 'form.label.iban',
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'reference',
    translationId: 'form.label.reference',
    type: FIELD_TYPES.inputText,
  },
  {
    name: 'verified',
    translationId: 'form.label.status',
    type: FIELD_TYPES.status,
    boolean: {
      label: {
        true: 'general.verified',
        false: 'general.unverified',
      },
    },
  },
];
