import PropTypes from 'prop-types';
import React from 'react';

const Input = props => {
  const { input: { onChange }, meta: { touched, error }, element, ...rest } = props;
  const isError = touched && typeof error !== 'undefined';
  const Element = element;
  return (
    <Element
      error={isError}
      // d.checked is used for checkbox while d.value is used for normal input method.
      onChange={(e, d) => onChange(d.value || d.checked)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};
Input.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  element: PropTypes.func.isRequired,
};

export default Input;
