import types from './types';

const { ADD, CLEAR } = types;

const initialState = [];

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD: {
      const notifications = Array.from(state);
      notifications.push(action.event);
      return notifications;
    }
    case CLEAR: {
      return [];
    }
    default:
      return state;
  }
}
