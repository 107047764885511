import memoize from 'lru-memoize';
import createValidator from '@htmniseko/htm-validators/utils/createValidator';
import required from '@htmniseko/htm-validators/utils/required';
import { passwordMatched, validatePassword, samePassword } from '@htmniseko/htm-validators/utils/password';

const passwordValidation = createValidator({
  repeatPassword: [required, passwordMatched],
  currentPassword: [required, samePassword],
  password: [required, validatePassword],
});
export default memoize(10)(passwordValidation);
