import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ClientManager, { setScreen } from '@htmniseko/htm-components/ClientManager';

/*
* This component monitors device screen width and store the information into the redux store.
*/

@connect(state => ({ screenWidth: state.client.screenWidth }), { setScreen })
export default class ClientManagerWrapper extends Component {
  static propTypes = {
    screenWidth: PropTypes.number.isRequired,
    setScreen: PropTypes.func.isRequired,
  };

  render() {
    const { screenWidth, setScreen } = this.props;
    const breakpoints = { desktop: '(min-width: 1200px)' };
    return (
      <ClientManager
        breakpoints={breakpoints}
        screenWidth={screenWidth}
        setScreen={setScreen}
      />
    );
  }
}
