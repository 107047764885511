import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

export default class AbsoluteLink extends PureComponent {
  static propTypes = {
    withTimestamp: PropTypes.bool,
    link: PropTypes.string.isRequired,
    textId: PropTypes.string.isRequired,
    descriptionId: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    withTimestamp: true,
    descriptionId: '',
    style: {},
  };

  render() {
    const { withTimestamp, link, textId, descriptionId, style } = this.props;
    return (
      <span style={style}>
        <a
          href={withTimestamp ? `${link}?mtime=${Date.now()}` : link}
          aria-label={textId}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id={textId} />
        </a>
        {
          descriptionId !== '' && (
            <span>
              &nbsp;–&nbsp;
              <FormattedMessage id={descriptionId} />
            </span>
          )
        }
      </span>
    );
  }
}
