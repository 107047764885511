import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const NotFound = loadable(() => import(/* webpackChunkName: "NotFound" */ './NotFound'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncNotFound = ({ route }) => (
  <NotFound route={route} />
);

asyncNotFound.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncNotFound;
