import PropTypes from 'prop-types';
import React from 'react';
import { Loader, Icon } from 'semantic-ui-react';

const ValidationStatus = ({ loading, success, failed }) => {
  if (loading) {
    return <Loader active inline size="small" style={{ marginTop: '10px', paddingLeft: '50px' }} />;
  }

  if (success) {
    return <Icon name="checkmark" size="large" style={{ color: 'green' }} />;
  }

  if (failed) {
    return <Icon name="close" size="large" style={{ color: 'red' }} />;
  }

  return null;
};

ValidationStatus.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
};

export default ValidationStatus;
