import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Header, Table } from 'semantic-ui-react';
import { ThemeButton } from 'components';

export default class StatementRow extends Component {
  static propTypes = {
    year: PropTypes.number,
    month: PropTypes.string.isRequired,
    balance: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  renderPropertyInfo() {
    const { balance } = this.props;
    return (
      <Header as="h2">
        {balance?.property?.strata ?? ''}
        <Header.Subheader>
          {balance?.property?.name ?? ''}
        </Header.Subheader>
      </Header>
    );
  }

  renderViewButton() {
    const { balance, year, month, onClick } = this.props;
    const strata = balance?.property?.strata;
    return (
      <ThemeButton
        fluid
        iconName="eye"
        titleId="general.view"
        disabled={!year || !month || month === 'All'}
        onClick={() => onClick(strata, false)}
      />
    );
  }

  renderDownloadButton() {
    const { balance, year, month, onClick } = this.props;
    const strata = balance?.property?.strata;
    return (
      <ThemeButton
        fluid
        iconName="download"
        titleId="general.download"
        disabled={!year || !month}
        onClick={() => onClick(strata, true)}
      />
    );
  }

  render() {
    return (
      <Table.Row>
        { /* Padding 3.5px 10.5px across all three cell below is a fix to inconsistent padding found when resizing the window */ }
        <Table.Cell width={8} style={{ minWidth: '150px', padding: '3.5px 10.5px' }}>
          { this.renderPropertyInfo() }
        </Table.Cell>
        <Table.Cell width={4} style={{ padding: '3.5px 10.5px' }}>
          { this.renderViewButton() }
        </Table.Cell>
        <Table.Cell width={4} style={{ padding: '3.5px 10.5px' }}>
          { this.renderDownloadButton() }
        </Table.Cell>
      </Table.Row>
    );
  }
}
