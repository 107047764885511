import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Container, List } from 'semantic-ui-react';
import { AbsoluteLink, ThemeHeader } from 'components';

import { contacts, links } from '../../constants';

export class SkyeNiseko extends PureComponent {
  static propTypes = {
    intl: PropTypes.object.isRequired,
  };

  render() {
    const { formatMessage } = this.props.intl;
    const email = contacts.email.skye;

    return (
      <Container style={{ fontSize: '20px', marginBottom: '50px' }}>
        {/* Owner Benefits */}
        <ThemeHeader titleId="page.general.info.benefits.header" />
        <p><FormattedMessage id="page.general.info.benefits.description.skye" /></p>
        {/* Concierge Services */}
        <p><strong><FormattedMessage id="page.general.info.benefits.description.concierge.title" /></strong></p>
        <p><FormattedMessage id="page.general.info.benefits.description.concierge.description" /></p>
        {/* Temporary commented out for now. Offer not valid for the 20/21 season */}
        {/* <List
          bulleted
          items={[
            formatMessage({ id: 'page.general.info.benefits.description.concierge.skiRental' }),
            formatMessage({ id: 'page.general.info.benefits.description.concierge.massage' }),
            formatMessage({ id: 'page.general.info.benefits.description.concierge.gosnow' }),
            formatMessage({ id: 'page.general.info.benefits.description.concierge.liftPasses' }),
            formatMessage({ id: 'page.general.info.benefits.description.concierge.transfers' }),
            formatMessage({ id: 'page.general.info.benefits.description.concierge.foodAndWine' }),
          ]}
        /> */}
        {/* Food & Beverage Discounts */}
        <p><strong><FormattedMessage id="page.general.info.benefits.description.foodAndBeverage.title" /></strong></p>
        <p><FormattedMessage id="page.general.info.benefits.description.foodAndBeverage.description" /></p>
        {/* Breakfast */}
        <p><strong><FormattedMessage id="page.general.info.benefits.description.breakfast.title" /></strong></p>
        <p><FormattedMessage id="page.general.info.benefits.description.breakfast.description" /></p>
        {/* Free Ski/Board Wax */}
        <p><strong><FormattedMessage id="page.general.info.benefits.description.freeWax.title" /></strong></p>
        <p><FormattedMessage id="page.general.info.benefits.description.freeWax.description" /></p>
        {/* Concierge Team contact */}
        <p>
          <FormattedMessage id="page.general.info.benefits.link.contact.skye" />
          &nbsp;
          (
          <a href={`mailto:${email}`}>{email}</a>
          ).
        </p>
        {/* Terms and Conditions */}
        <p><i><FormattedMessage id="general.termsAndConditions" /></i></p>
        <List
          bulleted
          items={[
            formatMessage({ id: 'page.general.info.termsAndConditions.skye.staying' }),
            formatMessage({ id: 'page.general.info.termsAndConditions.skye.bookThroughUs' }),
            formatMessage({ id: 'page.general.info.termsAndConditions.skye.ownerOnly' }),
            formatMessage({ id: 'page.general.info.termsAndConditions.skye.paymentMethod' }),
            formatMessage({ id: 'page.general.info.termsAndConditions.skye.guestDiscounts' }),
            formatMessage({ id: 'page.general.info.termsAndConditions.skye.discountsProcedure' }),
            formatMessage({ id: 'page.general.info.termsAndConditions.skye.discountsNotInclude' }),
          ]}
        />
        {/* Owner Tax Documents */}
        <ThemeHeader titleId="page.general.info.taxDocuments.header" />
        <p><FormattedMessage id="page.general.info.clickToDownload" /></p>
        <List
          items={
            Object.keys(links.skye.taxDocuments).map(link => (
              <AbsoluteLink
                key={link}
                link={links.skye.taxDocuments[link]}
                textId={`page.general.info.taxDocuments.${link}.text`}
                descriptionId={`page.general.info.taxDocuments.${link}.description`}
                style={{ display: 'block', margin: '10px 0' }}
              />
            ))
          }
        />
        <p>
          <AbsoluteLink
            link={links.skye.downloadAllTaxDocuments}
            textId="page.general.info.taxDocuments.downloadAll"
            style={{ fontStyle: 'italic' }}
          />
        </p>
        {/* Rates for Chargeable Work */}
        <ThemeHeader titleId="page.general.info.chargeableWorkRate.header" />
        <p><FormattedMessage id="page.general.info.clickToDownload" /></p>
        <p>
          <AbsoluteLink link={links.skye.chargeableWorkRate} textId="page.general.info.chargeableWorkRate.downloadLink" />
          &nbsp;
          <FormattedMessage id="page.general.info.chargeableWorkRate.lastUpdated.skye" />
        </p>
      </Container>
    );
  }
}

export default injectIntl(SkyeNiseko);
