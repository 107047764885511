import _ from 'lodash';
import isNumeric from '@htmniseko/htm-helpers/math/isNumeric';
import get from '@htmniseko/htm-helpers/misc/get';
import { SN_BLOCK_ID, NC_BLOCK_ID } from '../constants';

// Get tenants from contracts regardless if it is old/expired contracts
export function getTenants(contracts) {
  if (!contracts || !Array.isArray(contracts) || contracts.length < 1) {
    return [];
  }
  // Get unique values (blockId) from mapping through all the contracts
  return [...new Set(contracts.map(contract => get(contract, 'property.blockId')))].filter(Boolean);
}

// Group rooms by Strata & RoomType & BlockId (for grouping the same tenant's RoomType's room together)
export function groupRoomsByContracts(contracts) {
  // Get room contracts
  const roomContracts = contracts.filter(c => get(c, 'property.isSoldAsRoomType') === false);
  // Get room type contracts
  const roomTypeContracts = contracts.filter(c => get(c, 'property.isSoldAsRoomType') === true);
  // Group the room type contracts together
  const groupedRoomTypeContracts = _.chain(roomTypeContracts)
    .groupBy(c => (get(c, 'property.strata') && get(c, 'property.isSoldAsRoomType') && get(c, 'property.blockId')))
    .toPairs()
    .map(pair => pair[1][0]) // Returning the first contract from each group
    .value();
  // Return both room & room type contracts
  return roomContracts.concat(groupedRoomTypeContracts);
}

// Format a number to be in ',' separated format
export function formatNumber(number, options) {
  if (!isNumeric(number)) {
    return undefined;
  }

  const precision = (options && isNumeric(options.precision) && Number(options.precision) >= 0) ? Number(options.precision) : 2;

  const formattedNumber = Number(number).toFixed(precision).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (options && options.style === 'currency') {
    const currency = options.currency || '¥';
    const minus = formattedNumber.startsWith('-') ? '-' : '';
    const value = formattedNumber.startsWith('-') ? formattedNumber.substring(1) : formattedNumber;

    return `${minus}${currency}${value}`;
  }

  return formattedNumber;
}

// Custom decimals rounder where above 0.5 will be rounded up
// NOTE that "0.5" will be rounded down
export function round(number) {
  if (!isNumeric(number)) {
    return number;
  }
  return Math[(number % 1 > 0.5) ? 'ceil' : 'floor'](Number(number));
}

export function getTenantCode(id) {
  const tenants = [
    { id: 'nisekocentral', blockId: 1 },
    { id: 'kiniseko', blockId: 6 },
    { id: 'skye', blockId: 7 },
  ];

  if (typeof id === 'string') {
    return get(tenants.find(tenant => tenant.id === id), 'blockId');
  }
  return get(tenants.find(tenant => tenant.blockId === id), 'id');
}

// Filter out Niseko Central room-type properties and all Skye Niseko properties
export function shouldDisplayForOccupancy(property) {
  if (!property) {
    return false;
  }
  const blockId = get(property, 'blockId');
  const isRoomType = get(property, 'isSoldAsRoomType');
  // Hide all Skye Niseko room & room types
  if (blockId === SN_BLOCK_ID) {
    return false;
  }
  // Hide Niseko Central room types
  if (blockId === NC_BLOCK_ID && isRoomType) {
    return false;
  }
  return true;
}

export function removeWhiteSpace(string) {
  return string.replace(/\s/g, '');
}
