import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'components';

const HEADERS = [
  { id: 'folioId', content: <FormattedMessage id="form.label.folioId" /> },
  { id: 'room', content: <FormattedMessage id="form.label.room" /> },
  { id: 'status', content: <FormattedMessage id="form.label.status" /> },
  { id: 'confirmed', content: <FormattedMessage id="form.label.confirmed" /> },
  { id: 'checkIn', content: <FormattedMessage id="form.label.checkIn" /> },
  { id: 'checkOut', content: <FormattedMessage id="form.label.checkOut" /> },
  { id: 'nights', content: <FormattedMessage id="form.label.nights" /> },
];

export default class RoomBooking extends Component {
  static propTypes = {
    bookings: PropTypes.array.isRequired,
  };

  get body() {
    const { bookings } = this.props;
    // Sort bookings by arrival date
    const sorted = bookings.sort((a, b) => (new Date(a.arrivalDate) - new Date(b.arrivalDate)));

    return sorted.map(booking => ({
      id: booking.id,
      content: [
        booking.id,
        booking.room.code,
        booking.folioStatus,
        booking.confirmed,
        moment(booking.arrivalDate).format('l'),
        moment(booking.departDate).format('l'),
        moment(booking.departDate).diff(moment(booking.arrivalDate), 'days'),
      ],
    }));
  }

  get footer() {
    const { bookings } = this.props;
    const totalNights = bookings.reduce((a, b) => a + moment(b.departDate).diff(moment(b.arrivalDate), 'days'), 0);

    return [
      {
        id: 'totalBookings',
        colSpan: '6',
        title: <FormattedMessage id="form.label.totalBookings" />,
        content: bookings.length,
      },
      { id: 'totalNights', index: 6, content: totalNights },
    ];
  }

  render() {
    return <Table headers={HEADERS} body={this.body} footer={this.footer} />;
  }
}
