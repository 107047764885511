import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'semantic-ui-react';

const NavigationButtons = ({ activeIndex, maxIndex, disableNext, onPrevious, onConfirm, onNext }) => {
  const isLastIndex = activeIndex === maxIndex;

  return (
    <div style={{ marginTop: '20px' }}>
      <Button color="blue" floated="left" disabled={activeIndex === 0} onClick={() => onPrevious()}>
        <FormattedMessage id="general.back" />
      </Button>
      <Button color="green" floated="right" disabled={disableNext} onClick={() => (isLastIndex ? onConfirm() : onNext())}>
        { isLastIndex ? <FormattedMessage id="general.confirm" /> : <FormattedMessage id="general.next" /> }
      </Button>
    </div>
  );
};

NavigationButtons.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  maxIndex: PropTypes.number.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  disableNext: PropTypes.bool,
};

export default NavigationButtons;
