import isEqual from 'lodash/isEqual';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { requestBooking } from 'redux/modules/booking';
import { clearStore, loadActiveContracts } from 'redux/modules/contract';
import get from '@htmniseko/htm-helpers/misc/get';
import set from '@htmniseko/htm-helpers/misc/set';
import { open as openDatepicker } from '@htmniseko/htm-ui/DatePicker';
import { NoDataFound } from 'components';
import { RequestBookingForm } from 'components/Form';

const NC_BLOCK_ID = 1;
const JAPAN_OFFSET = 9;
const REQUIRED_FIELDS = [
  'room',
  'stay.from',
  'stay.to',
  'pax.adults',
  'pax.children',
  'pax.infants',
  'paymentMethod',
];

const defaultValues = {
  pax: {
    adults: 1,
    children: 0,
    infants: 0,
  },
  bedConfiguration: 'I\'ll decide later',
  cleaning: 'Default',
  ownerStorageKey: 'No',
  paymentMethod: 'Owner Account',
};

export class RequestBooking extends PureComponent {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    bedConfigText: PropTypes.object.isRequired,
    contractStore: PropTypes.object.isRequired,
    datepicker: PropTypes.object.isRequired,
    tenantId: PropTypes.string.isRequired,
    clearStore: PropTypes.func.isRequired,
    loadActiveContracts: PropTypes.func.isRequired,
    requestBooking: PropTypes.func.isRequired,
    openDatepicker: PropTypes.func.isRequired,
  };

  state = {
    booking: defaultValues,
    bedConfigs: [],
    errors: [],
    allowCleaningOption: false,
    isSubmitting: false,
  };

  // Load owner's contracts after component mounted
  componentDidMount() {
    const { auth: { user } } = this.props;
    // Include Q-Rooms
    const includes = { qRooms: true };
    this.props.loadActiveContracts(user.id, includes);
  }

  // Update dates when datepicker updates
  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props.datepicker;
    if (!isEqual(prevProps.datepicker.startDate, startDate) || !isEqual(prevProps.datepicker.endDate, endDate)) {
      // This will cause a second re-rendering :/
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => {
        const booking = { ...prevState.booking,
          stay: {
            from: startDate ? startDate.utcOffset(JAPAN_OFFSET).format('l') : '',
            to: endDate ? endDate.utcOffset(JAPAN_OFFSET).format('l') : '',
          } };
        return { booking };
      });
    }
  }

  componentWillUnmount() {
    // Clean up redux store when unmounting the tab
    this.props.clearStore();
  }

  handleBack() {
    this.setState({ isSubmitting: false });
  }

  handleChange(key, value) {
    // Handling room selection
    if (key === 'room') {
      this.handleRoomSelect(value);
    }
    else {
      // Update booking values in state
      this.setState(prevState => {
        const booking = { ...prevState.booking };
        // Update new changes
        set(booking, key, value);
        return { booking };
      });
    }
  }

  handleRoomSelect(roomCode) {
    this.setState(prevState => this.getNewBookingDetails(prevState.booking, roomCode));
  }

  handleSubmit() {
    const { auth: { admin, user, token }, tenantId } = this.props;
    // Include requester info into the data before requesting
    const booking = { ...this.state.booking, requester: admin ? 'admin' : 'owner' };
    // Send booking request
    this.props.requestBooking(user.id, booking, tenantId, token);
    // Reset to initial values
    this.setState({
      booking: defaultValues,
      errors: [],
      isSubmitting: false,
    });
  }

  handleRequest() {
    const { booking } = this.state;

    // Check if any required field is empty or undefined
    const errors = REQUIRED_FIELDS.filter(key => (get(booking, key) === undefined || get(booking, key) === ''));

    // Check if startDate and endDate are valid
    const { from, to } = booking?.stay ?? {};

    if (from && to && moment(to).isSameOrBefore(moment(from))) {
      errors.push('Invalid date range');
    }

    // Submit if there is no errors
    if (errors.length === 0) {
      this.setState({ booking, isSubmitting: true });
    }

    // Update the errors array in state
    this.setState({ errors });
  }

  handleDatepickerOpen() {
    this.props.openDatepicker();
  }

  getNewBookingDetails(booking, roomCode) {
    const { bedConfigText, contractStore: { contracts } } = this.props;
    const newBooking = { ...booking };

    // Get the selected room contract
    const contract = contracts.find(contract => contract?.property?.code === roomCode);

    // Update new changes
    set(newBooking, 'room', roomCode);
    // Add tenant ID
    set(newBooking, 'tenantId', contract?.property?.blockId);

    // Get the bed configuration IDs from the contract
    const bedConfigs = contract?.property?.bedConfigurations ?? [];

    // Determine if the room should have cleaning options
    // Return true for Niseko Central room
    const allowCleaningOption = contract?.property?.blockId === NC_BLOCK_ID;

    return {
      allowCleaningOption,
      booking: newBooking,
      bedConfigs: bedConfigs.map(id => bedConfigText[id]).filter(Boolean),
    };
  }

  render() {
    const { allowCleaningOption, booking, bedConfigs, errors, isSubmitting } = this.state;
    const { tenantId } = this.props;
    const { loading, contracts } = this.props.contractStore;
    const rooms = contracts.map(c => c?.property?.code).filter(Boolean);
    // Find the selected room
    const room = contracts.find(c => c?.property?.code === booking.room);
    // Return true for Niseko Central room
    const shouldRenderCleaning = room?.property?.blockId === NC_BLOCK_ID;

    // Display confirmation before the final submission
    if (isSubmitting) {
      return (
        <RequestBookingForm
          tenantId={tenantId}
          allowCleaningOption={shouldRenderCleaning}
          data={booking}
          onBack={() => this.handleBack()}
          onSubmit={() => this.handleSubmit()}
        />
      );
    }

    if (loading) {
      return null;
    }

    if (rooms.length < 1) {
      return <NoDataFound />;
    }

    // Request booking form
    return (
      <RequestBookingForm
        tenantId={tenantId}
        allowCleaningOption={allowCleaningOption}
        bedConfigs={bedConfigs}
        data={booking}
        errors={errors}
        roomNames={rooms}
        onDateClick={() => this.handleDatepickerOpen()}
        onChange={(key, value) => this.handleChange(key, value)}
        onSubmit={() => this.handleRequest()}
      />
    );
  }
}

export default connect(
  state => ({
    auth: state.auth,
    bedConfigText: state.intl.bedConfigText,
    datepicker: state.datepicker,
    tenantId: state.tenant.id,
    contractStore: state.contract,
  }),
  { clearStore, loadActiveContracts, requestBooking, openDatepicker }
)(RequestBooking);
