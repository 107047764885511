import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ChildRoutes from 'helpers/ChildRoutes';
import { getTenants } from 'utils/misc';

export class ChildrenRoutes extends PureComponent {
  static propTypes = {
    admin: PropTypes.oneOfType([
      // Object when it exists, bool when it's not.
      PropTypes.object,
      PropTypes.bool,
    ]),
    locale: PropTypes.string.isRequired,
    route: PropTypes.object.isRequired,
    tenantId: PropTypes.string.isRequired,
    user: PropTypes.object,
  };

  static defaultProps = {
    admin: false,
    user: null,
  };

  get userAllowedTenants() {
    const { user } = this.props;
    return user ? getTenants(user.contracts) : [];
  }

  render() {
    const { admin, locale, route, tenantId, user } = this.props;
    return (
      <div style={{ margin: '20px', minHeight: '65vh' }}>
        <ChildRoutes
          isAdmin={!!admin}
          isUser={user !== null}
          locale={locale}
          routes={route.routes}
          tenantId={tenantId}
          userAllowedTenants={this.userAllowedTenants}
        />
      </div>
    );
  }
}

export default connect(state => ({
  admin: state.auth.admin,
  locale: state.intl.locale,
  tenantId: state.tenant.id,
  user: state.auth.user,
}))(ChildrenRoutes);
