import {
  AccessDenied, App, Booking, ChangePassword,
  ForgotPassword, CurrentStatus, HistoricalStatements, LoginPage,
  NotFound, PasswordReset, RequestBooking, Occupancy, SelectOwnerId,
  UsefulInfo, RequestHistory, RequestPayment, TwoFactorAuth, MyDetails,
  ComingSoon, ContactUs,
} from 'facades';
import BankAccounts from './facades/User/BankAccounts/BankAccounts';
import { tenants, KN_BLOCK_ID, NC_BLOCK_ID, SN_BLOCK_ID } from './constants';
import { ROUTE_KEY as BANK_ACCOUNTS_ROUTE_KEY, ROUTE_PATH as BANK_ACCOUNTS_ROUTE_PATH } from './facades/User/BankAccounts/constants';

const routes = [
  {
    component: App,
    routes: [
      { key: 'root', path: '/:locale/', exact: true, private: true, redirectTo: 'financial/currentStatus' },
      // Financial
      { key: 'currentStatus', path: '/:locale/financial/currentStatus', exact: true, private: true, component: CurrentStatus },
      { key: 'payment', path: '/:locale/financial/payment', exact: true, private: true, component: RequestPayment },
      { key: 'historicalStatements', path: '/:locale/financial/historicalStatements', exact: true, private: true, component: HistoricalStatements },
      // Occupancy
      { key: 'occupancy', path: '/:locale/occupancy', exact: true, private: true, excludeTenant: tenants[SN_BLOCK_ID].name, component: Occupancy },
      // User
      { key: 'me', path: '/:locale/user/me', exact: true, private: true, component: MyDetails },
      { key: BANK_ACCOUNTS_ROUTE_KEY, path: `/:locale${BANK_ACCOUNTS_ROUTE_PATH}`, exact: true, private: true, component: BankAccounts },
      { key: 'changePassword', path: '/:locale/user/changePassword', exact: true, private: true, component: ChangePassword },
      // Booking
      { key: 'bookingExisting', path: '/:locale/booking/existing', exact: true, private: true, component: Booking },
      { key: 'bookingRequest', path: '/:locale/booking/request', exact: true, private: true, component: RequestBooking },
      // General
      { key: 'usefulInfoKi', path: '/:locale/general/kiniseko', exact: true, private: true, requiredTenantId: KN_BLOCK_ID, component: UsefulInfo },
      { key: 'usefulInfoNC', path: '/:locale/general/nisekocentral', exact: true, private: true, requiredTenantId: NC_BLOCK_ID, component: UsefulInfo },
      { key: 'usefulInfoSkye', path: '/:locale/general/skye', exact: true, private: true, requiredTenantId: SN_BLOCK_ID, component: UsefulInfo },
      { key: 'contactUs', path: '/:locale/general/contactUs', exact: true, private: true, component: ContactUs },
      // History
      { key: 'history', path: '/:locale/history', exact: true, private: true, component: RequestHistory },
      // Admin
      { key: 'selectOwnerId', path: '/:locale/selectOwnerId', exact: true, admin: true, component: SelectOwnerId },
      // Login
      { key: 'login', path: '/:locale/login', exact: true, private: false, component: LoginPage },
      { key: 'passwordReset', path: '/:locale/passwordReset', exact: true, component: PasswordReset },
      { key: 'forgotPassword', path: '/:locale/forgotPassword', exact: true, component: ForgotPassword },
      { key: 'verify2faCode', path: '/:locale/verify2faCode', exact: true, component: TwoFactorAuth },
      // Others
      { key: 'accessDenied', path: '/:locale/accessDenied', component: AccessDenied },
      { key: 'comingsoon', path: '/:locale/comingsoon', component: ComingSoon },
      { key: 'not-found', component: NotFound },
    ],
  },
];

export default routes;
