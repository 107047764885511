import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPasswordValidationDetails } from '@htmniseko/htm-validators/utils/password';
import { Icon, List } from 'semantic-ui-react';

const PasswordValidationHelper = ({ password }) => {
  const { formatMessage } = useIntl();
  const [validationStatus, setValidationStatus] = useState(getPasswordValidationDetails(password));

  useEffect(() => {
    const validation = getPasswordValidationDetails(password);
    setValidationStatus(validation);
  }, [password]);

  const { hasMinLength, hasLowerCase, hasUpperCase, hasNumber, hasSymbol, hasMinCombination } = validationStatus;

  return (
    <List size="large">
      <List.Item>
        <List.Icon name="warning" />
        <List.Content>
          <List.Header>
            <FormattedMessage id="page.user.changePassword.passwordRequirements" />
            :
          </List.Header>
        </List.Content>
        <List.Item>
          <List.List>
            <List.Item
              icon={<Icon name="checkmark" color={hasMinLength ? 'green' : 'red'} />}
              content={formatMessage({ id: 'page.user.changePassword.has8Chars' })}
            />
            <List.Item
              icon={<Icon name="checkmark" color={hasMinCombination ? 'green' : 'red'} />}
              content={formatMessage({ id: 'page.user.changePassword.atleast2Combinations' })}
            />
            <List.Item>
              <List.List style={{ marginLeft: '20px' }}>
                <List.Item
                  icon={<Icon name="checkmark" color={hasLowerCase ? 'green' : 'grey'} />}
                  content={formatMessage({ id: 'page.user.changePassword.lowerCase' })}
                />
                <List.Item
                  icon={<Icon name="checkmark" color={hasUpperCase ? 'green' : 'grey'} />}
                  content={formatMessage({ id: 'page.user.changePassword.upperCase' })}
                />
                <List.Item
                  icon={<Icon name="checkmark" color={hasNumber ? 'green' : 'grey'} />}
                  content={formatMessage({ id: 'page.user.changePassword.numbers' })}
                />
                <List.Item
                  icon={<Icon name="checkmark" color={hasSymbol ? 'green' : 'grey'} />}
                  content={formatMessage({ id: 'page.user.changePassword.symbols' })}
                />
              </List.List>
            </List.Item>
          </List.List>
        </List.Item>
      </List.Item>
    </List>
  );
};

PasswordValidationHelper.propTypes = {
  password: PropTypes.string,
};

PasswordValidationHelper.defaultProps = {
  password: '',
};

export default PasswordValidationHelper;
