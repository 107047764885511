import { getTrustedDeviceCookie } from 'utils/cookies/getCookies';
import types from './types';

const {
  AUTH_CALLBACK, AUTH_INIT,
  LOGIN, LOGIN_SUCCESS, LOGIN_FAIL,
  REFRESH, REFRESH_SUCCESS, REFRESH_FAIL,
  VERIFY, VERIFY_SUCCESS, VERIFY_FAIL,
  RESEND, RESEND_SUCCESS, RESEND_FAIL, RESEND_CLEAR,
  LOAD_OWNERS, LOAD_OWNERS_SUCCESS, LOAD_OWNERS_FAIL,
  SEND_REQUEST, SEND_REQUEST_SUCCESS, SEND_REQUEST_FAIL,
  VALIDATE_MOBILE, VALIDATE_MOBILE_SUCCESS, VALIDATE_MOBILE_FAIL,
  SET_USER, CLEAN_UP,
} = types;

export function cleanup() {
  return { type: CLEAN_UP };
}

export function clearResend2FaStatus() {
  return { type: RESEND_CLEAR };
}

export function authenticate(provider, tenant, code) {
  // If code is provided, validate the authentication
  if (provider === 'google' && code) {
    const trustedDeviceId = getTrustedDeviceCookie();
    const params = trustedDeviceId ? { code, trustedDeviceId } : { code };

    return {
      types: [LOGIN, AUTH_CALLBACK, LOGIN_FAIL],
      promise: client => client.get(`/OwnerAuth/${provider}/callback`, { params, tenant }),
    };
  }

  // Otherwise initialize the authentication
  return {
    types: [LOGIN, AUTH_INIT, LOGIN_FAIL],
    promise: client => client.get(`/OwnerAuth/${provider}`, { tenant }),
  };
}

export function login(username, password, tenant) {
  const trustedDeviceId = getTrustedDeviceCookie();
  const data = { username, password, trustedDeviceId };

  return {
    types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
    promise: client => client.post(`/OwnerAuth/login?tenant=${tenant}`, { data }),
  };
}

export function logout() {
  // Navigating to the logout path will allow the server-side to handle the logout request and then easily reset the application state
  window.location.assign(`${window.location.origin}/logout`);
}

export function refresh() {
  return {
    types: [REFRESH, REFRESH_SUCCESS, REFRESH_FAIL],
    promise: client => client.post('/OwnerAuth/refresh'),
  };
}

export function resend2faCode(username, tenant, recipientHash, deliveryMethod, twoFactorRequestId) {
  // In the case of logging when when a 2FA option is forced (such as admin login), the recipientHash and deliveryMethod will be null
  // since the user will not have selected a recipient or delivery method.
  // However a null value for recipientHash and deliveryMethod must not be included as "null" in the payload, so explicitly set it as undefined.
  const data = {
    username,
    tenant,
    recipientHash: recipientHash ?? undefined,
    deliveryMethod: deliveryMethod ?? undefined,
    twoFactorRequestId: twoFactorRequestId ?? undefined,
  };

  return {
    types: [RESEND, RESEND_SUCCESS, RESEND_FAIL],
    promise: client => client.post(`/OwnerAuth/resend2faCode?tenant=${tenant}`, { data }),
  };
}

export function getOwners(token) {
  const filter = { include: [{ relation: 'contracts', scope: { include: ['property'] } }] };
  return {
    types: [LOAD_OWNERS, LOAD_OWNERS_SUCCESS, LOAD_OWNERS_FAIL],
    promise: client => client.get(`/owners/find?filter=${encodeURIComponent(JSON.stringify(filter))}`, { token }),
  };
}

export function setUser(user) {
  return { type: SET_USER, user };
}

export function requestInfoUpdates(userId, data, tenant) {
  return {
    types: [SEND_REQUEST, SEND_REQUEST_SUCCESS, SEND_REQUEST_FAIL],
    notifications: [null, 'notifications.requestInfoUpdates.successful', null],
    promise: client => client.post(`/owners/${userId}/requestInfoUpdates`, { data, tenant }),
  };
}

export function verify2faCode(username, tenant, twoFactorRequestId, code) {
  const trustedDeviceId = getTrustedDeviceCookie();

  return {
    types: [VERIFY, VERIFY_SUCCESS, VERIFY_FAIL],
    promise: client => client.post('/OwnerAuth/verify2faCode', { data: { username, tenant, twoFactorRequestId, code, trustDevice: trustedDeviceId } }),
  };
}

// Currently, validate phone is used just for validating the user's profile.
// If it is used elsewhere or have another same method of validation. It's better to move to a dedicated redux module.
export function validateMobile(phoneNumber) {
  return {
    types: [VALIDATE_MOBILE, VALIDATE_MOBILE_SUCCESS, VALIDATE_MOBILE_FAIL],
    promise: client => client.post('/phones/validatePhoneNumber', { data: { phoneNumber } }),
  };
}
