import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Grid, GridColumn, GridRow, Header, Message } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import ThemeButton from '../Theme/ThemeButton';

const ContactUsWidget = () => {
  const { locale } = useIntl();
  const history = useHistory();
  const { id: tenantId, theme: { colors } } = useSelector(state => state.tenant);
  const { screenType } = useSelector(state => state.client);

  const onClick = () => {
    history.push(`/${locale}/general/contactUs`);
  };

  return (
    <Container style={{ width: screenType !== 'desktop' ? '100%' : '50%' }}>
      <Message color={tenantId === 'nisekocentral' ? '#f7f6f6' : colors.secondary}>
        <Grid verticalAlign="middle">
          <GridRow>
            <GridColumn width={12}>
              <Header as="h4" style={{ color: colors.primary }}>
                <FormattedMessage id="page.general.contactUs.title" />
                <Header.Subheader>
                  <FormattedMessage id="page.general.contactUs.subtitle" />
                </Header.Subheader>
              </Header>
            </GridColumn>
            <GridColumn width={4}>
              <ThemeButton style={{ float: 'right' }} titleId="page.general.contactUs.button" onClick={onClick} size="small" />
            </GridColumn>
          </GridRow>
        </Grid>
      </Message>
    </Container>
  );
};

export default ContactUsWidget;
