import { connect } from 'react-redux';
import { push } from 'redux/modules/router';
import Facet, { toggleFilter } from '@htmniseko/htm-components/Facet';

// Higher Order Component that adds filtering functionality for the wrapped component
// TODO Handle undefined attributes gracefully; happens when using this class only to search
export default function facetFactory(attribute) {
  return function facetHoC(WrappedComponent) {
    return connect(state => ({
      filters: state.facets.filters,
      location: state.router.location,
    }), {
      push,
      toggleFilter,
    })(Facet(attribute)(WrappedComponent));
  };
}
