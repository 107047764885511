const types = {
  LOGIN: 'app/auth/LOGIN',
  LOGIN_SUCCESS: 'app/auth/LOGIN_SUCCESS',
  LOGIN_FAIL: 'app/auth/LOGIN_FAIL',
  REFRESH: 'app/auth/REFRESH',
  REFRESH_SUCCESS: 'app/auth/REFRESH_SUCCESS',
  REFRESH_FAIL: 'app/auth/REFRESH_FAIL',
  VERIFY: 'app/auth/VERIFY',
  VERIFY_SUCCESS: 'app/auth/VERIFY_SUCCESS',
  VERIFY_FAIL: 'app/auth/VERIFY_FAIL',
  VALIDATE_MOBILE: 'app/auth/VALIDATE_MOBILE',
  VALIDATE_MOBILE_SUCCESS: 'app/auth/VALIDATE_MOBILE_SUCCESS',
  VALIDATE_MOBILE_FAIL: 'app/auth/VALIDATE_MOBILE_FAIL',
  RESEND_CLEAR: 'app/auth/RESEND_CLEAR',
  RESEND: 'app/auth/RESEND',
  RESEND_SUCCESS: 'app/auth/RESEND_SUCCESS',
  RESEND_FAIL: 'app/auth/RESEND_FAIL',
  AUTH_INIT: 'app/auth/AUTHENTICATE',
  AUTH_CALLBACK: 'app/auth/AUTHENTICATE_CALLBACK',
  LOAD_OWNERS: 'app/auth/LOAD_OWNERS',
  LOAD_OWNERS_SUCCESS: 'app/auth/LOAD_OWNERS_SUCCESS',
  LOAD_OWNERS_FAIL: 'app/auth/LOAD_OWNERS_FAIL',
  SEND_REQUEST: 'app/auth/SEND_REQUEST',
  SEND_REQUEST_SUCCESS: 'app/auth/SEND_REQUEST_SUCCESS',
  SEND_REQUEST_FAIL: 'app/auth/SEND_REQUEST_FAIL',
  SET_USER: 'app/auth/SET_USER',
  CLEAN_UP: 'app/shift/CLEAN_UP',
};

export default types;
