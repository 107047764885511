/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { Form, Input } from 'semantic-ui-react';

const Field = props => {
  const { element, editable, isFormField, size, value, ...rest } = props;
  const Element = element;

  if (!editable) {
    return (
      <span style={{ fontSize: '1.2em', wordWrap: 'break-word' }}>{value}</span>
    );
  }
  if (!isFormField) {
    return (
      <Element value={value} {...rest} />
    );
  }
  return (
    <Form size={size}>
      <Element value={value} {...rest} />
    </Form>
  );
};

Field.propTypes = {
  editable: PropTypes.bool,
  element: PropTypes.func,
  isFormField: PropTypes.bool,
  size: PropTypes.string,
  value: PropTypes.any,
};
Field.defaultProps = {
  editable: true,
  element: Input,
  isFormField: false,
  size: 'large',
};

export default Field;
