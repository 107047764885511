import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Header, Icon, Message } from 'semantic-ui-react';

import { ownersContacts } from '../../constants';

const NoDataFound = ({ iconColor, iconName, message }) => {
  const tenantId = useSelector(state => state.tenant.id);
  const defaultMessage = <FormattedMessage id="error.noDataFound" values={{ email: ownersContacts.email[tenantId] }} />;

  return (
    <Message size="massive" style={{ background: 'none', boxShadow: 'none' }}>
      <Icon
        size="huge"
        color={iconColor}
        name={iconName}
        style={{ margin: '20px auto', display: 'block' }}
      />
      <Header as="h3" style={{ margin: '20px', textAlign: 'center' }}>
        { message || defaultMessage }
      </Header>
    </Message>
  );
};

NoDataFound.propTypes = {
  iconColor: PropTypes.string,
  iconName: PropTypes.string,
  message: PropTypes.string,
};

NoDataFound.defaultProps = {
  iconColor: 'orange',
  iconName: 'warning circle',
};

export default NoDataFound;
