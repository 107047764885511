import types from './types';

const {
  RESET,
  LOAD, LOAD_SUCCESS, LOAD_FAIL,
} = types;

const initialState = {
  loading: false,
  loaded: false,
  histories: [],
  error: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        histories: [],
        error: {},
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        histories: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case RESET:
      return { ...initialState };
    default:
      return state;
  }
}
