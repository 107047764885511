import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

const RenderRoute = ({ locale, location, isAdmin, isUser, route, tenantId, userAllowedTenants }) => {
  // If it's a protected route and it's an Admin but has not selected a user
  if (route.private && isAdmin && !isUser) {
    return <Redirect to={`/${locale}/selectOwnerId?nextURL=${location.pathname}`} />;
  }

  // If the user is protected and the user is not authenticated
  if (route.private && !isUser) {
    const nextUrl = location.pathname ? `nextURL=${location.pathname}` : '';
    // Include the URL search queries in the Login URL, it's needed when login in with Google
    const search = location.search ? `${location.search}&${nextUrl}` : `?${nextUrl}`;
    return <Redirect to={`/${locale}/login${search}`} />;
  }

  // If the route is only for administrator and the user is not an administrator
  if (route.admin && !isAdmin) {
    return <Redirect to={`/${locale}/login`} />;
  }

  if (tenantId && route.excludeTenant === tenantId) {
    return <Redirect to={`/${locale}/comingsoon`} />;
  }

  if (route.requiredTenantId && !userAllowedTenants.includes(route.requiredTenantId)) {
    return <Redirect to={`/${locale}/accessdenied`} />;
  }

  if (route.redirectTo && typeof route.redirectTo === 'string') {
    return <Redirect to={route.redirectTo} />;
  }

  return (
    <route.component
      route={route}
      location={location}
    />
  );
};

RenderRoute.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isUser: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  tenantId: PropTypes.string,
  userAllowedTenants: PropTypes.arrayOf(PropTypes.number),
};

export default RenderRoute;
