/**
 * This action type will be dispatched by the history actions below.
 * If you're writing a middleware to watch for navigation events, be sure to
 * look for actions of this type.
 * Copy of https://github.com/reactjs/react-router-redux/blob/master/src/actions.js
 */
export const CALL_HISTORY_METHOD = '@@router/CALL_HISTORY_METHOD';

function updateLocation(method) {
  return path => {
    // React-Routers enhanced histories do not work properly in this version (it's not possible to define a basename)
    // So we use this small customization to adjust every link path with the basename prefix
    const localeBase = '/en';
    // If there is already a locale set on the path, we will follow with the requested path
    const adjustedPath = path.startsWith('/en') ? path : `${localeBase}${path}`;
    return ({
      type: CALL_HISTORY_METHOD,
      payload: { method, args: [adjustedPath] },
    });
  };
}

/**
 * These actions correspond to the history API.
 * The associated routerMiddleware will capture these events before they get to
 * your reducer and reissue them as the matching function on your history.
 */
export const push = updateLocation('push');
export const replace = updateLocation('replace');
export const go = updateLocation('go');
export const goBack = updateLocation('goBack');
export const goForward = updateLocation('goForward');

export const routerActions = { push, replace, go, goBack, goForward };
