// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DayPicker--horizontal {
  box-shadow: none;
  margin-left: -7px;
}

.CalendarDay--highlighted-calendar:active {
  background: #005486;
}

.CalendarDay--selected-span {
  background: #3A97D8;
  border: 1px double #3A97D8;
  color: #fff;
}

.CalendarDay--selected-span.CalendarDay--hovered, .CalendarDay--selected-span:active {
  background: #005486;
  border: 1px double #005486;
}

.CalendarDay--selected-span.CalendarDay--last-in-range {
  border-right: #005486;
}

.CalendarDay--hovered-span,
.CalendarDay--after-hovered-start {
  background: #3A97D8;
  border: 1px double #3A97D8;
  color: #fff;
}

.CalendarDay--hovered-span:active,
.CalendarDay--after-hovered-start:active {
  background: #005486;
}

.CalendarDay--selected-start,
.CalendarDay--selected-end,
.CalendarDay--selected {
  background: #005486;
  border: 1px double #005486;
  color: #fff;
}

.CalendarDay--selected-start:active,
.CalendarDay--selected-end:active,
.CalendarDay--selected:active {
  background: #005486;
}

.CalendarMonth {
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DatePicker/DatePicker.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;EAC1B,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;;EAEE,mBAAmB;EACnB,0BAA0B;EAC1B,WAAW;AACb;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;;;EAGE,mBAAmB;EACnB,0BAA0B;EAC1B,WAAW;AACb;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".DayPicker--horizontal {\n  box-shadow: none;\n  margin-left: -7px;\n}\n\n.CalendarDay--highlighted-calendar:active {\n  background: #005486;\n}\n\n.CalendarDay--selected-span {\n  background: #3A97D8;\n  border: 1px double #3A97D8;\n  color: #fff;\n}\n\n.CalendarDay--selected-span.CalendarDay--hovered, .CalendarDay--selected-span:active {\n  background: #005486;\n  border: 1px double #005486;\n}\n\n.CalendarDay--selected-span.CalendarDay--last-in-range {\n  border-right: #005486;\n}\n\n.CalendarDay--hovered-span,\n.CalendarDay--after-hovered-start {\n  background: #3A97D8;\n  border: 1px double #3A97D8;\n  color: #fff;\n}\n\n.CalendarDay--hovered-span:active,\n.CalendarDay--after-hovered-start:active {\n  background: #005486;\n}\n\n.CalendarDay--selected-start,\n.CalendarDay--selected-end,\n.CalendarDay--selected {\n  background: #005486;\n  border: 1px double #005486;\n  color: #fff;\n}\n\n.CalendarDay--selected-start:active,\n.CalendarDay--selected-end:active,\n.CalendarDay--selected:active {\n  background: #005486;\n}\n\n.CalendarMonth {\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
