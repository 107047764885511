import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Dropdown, Message, TextArea, Grid } from 'semantic-ui-react';
import get from '@htmniseko/htm-helpers/misc/get';
import { Field, Label, ThemeButton } from 'components';

const CLEANING_OPTIONS = [
  'cleaningOptions.eco',
  'cleaningOptions.fullServiced',
  'cleaningOptions.other',
];
const PAYMENT_OPTIONS = [
  'paymentOptions.ownerAccount',
  'paymentOptions.cashCreditCard',
];
const STORAGE_KEY_OPTIONS = [
  'storageKeyOptions.yes',
  'storageKeyOptions.no',
];
const SERVICE_OPTIONS = [
  'serviceOptions.transfers',
  'serviceOptions.liftPasses',
  'serviceOptions.rentals',
  'serviceOptions.lessons',
  'serviceOptions.carRentals',
];
export class RequestBookingForm extends PureComponent {
  static propTypes = {
    allowCleaningOption: PropTypes.bool.isRequired,
    tenantId: PropTypes.string.isRequired,
    bedConfigs: PropTypes.array,
    data: PropTypes.object,
    errors: PropTypes.array,
    roomNames: PropTypes.array,
    onDateClick: PropTypes.func,
    onBack: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  static defaultProps = {
    bedConfigs: [],
    data: {},
    errors: [],
    roomNames: [],
  };

  handleDatepickerOpen() {
    if (typeof this.props.onDateClick === 'function') {
      this.props.onDateClick();
    }
  }

  handleBack(event) {
    event.preventDefault();
    if (typeof this.props.onBack === 'function') {
      this.props.onBack();
    }
  }

  handleChange(key, value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(key, value);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onSubmit();
  }

  get editable() {
    return typeof this.props.onChange === 'function';
  }

  getOptions(textArray, config) {
    const { formatMessage } = this.props.intl;
    let textValues = textArray;
    // If the message should be formatted
    if (config && config.shouldFormatMessage) {
      textValues = textArray.map(id => formatMessage({ id: `form.requestBooking.${id}` }));
    }
    // Map each values into an option object
    const options = textValues.map(text => ({ text, key: text, value: text }));
    // Include "I'll decide later" option into the dropdown
    if (config && config.includeDecideLater && !textValues.includes('I\'ll decide later')) {
      const decideLater = formatMessage({ id: 'form.requestBooking.decideLater' });
      options.push({ text: decideLater, key: decideLater, value: decideLater });
    }
    // Include "Default" option into the dropdown
    if (config && config.includeDefault && !textValues.includes('Default')) {
      const defaultOpt = formatMessage({ id: 'form.requestBooking.default' });
      options.push({ text: defaultOpt, key: defaultOpt, value: defaultOpt });
    }
    return options;
  }

  renderDropdownField(key, options, required = true, multiple = false, note = null) {
    const { errors, data } = this.props;
    let value = get(data, key, '');
    if (multiple) {
      value = this.editable ? get(data, key, []) : get(data, key, []).join(', ');
    }

    return (
      <Grid.Row>
        <Grid.Column style={{ display: 'flex', flexDirection: 'column', alignItems: 'top' }}>
          <Label required={required} id={`form.label.${key}`} style={{ width: '370px' }} />
          {note && (
            <Label style={{ padding: 0, fontWeight: 'normal', fontSize: '0.7em', lineHeight: '1' }} id={note} />
          )}
        </Grid.Column>
        <Grid.Column style={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}>
          <Field
            selection
            multiple={multiple}
            editable={this.editable}
            element={Dropdown}
            options={options}
            error={errors.includes(key)}
            value={value}
            style={{ maxHeight: '18px', minWidth: '210px' }}
            onChange={(event, data) => this.handleChange(key, data.value)}
          />
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderPaxField(key) {
    const { errors, data } = this.props;
    return (
      <Form.Field inline style={{ display: this.editable ? 'inline-block' : 'block', padding: '0' }}>
        <Label required id={`form.label.${key}`} style={{ fontSize: '0.9em', width: '222px' }} />
        <Field
          min="1"
          type="number"
          editable={this.editable}
          element={Form.Input}
          style={{ minWidth: '210px' }}
          value={get(data, `pax.${key}`, '')}
          error={errors.includes(`pax.${key}`)}
          onChange={(event, data) => this.handleChange(`pax.${key}`, data.value)}
        />
      </Form.Field>
    );
  }

  renderTextAreaField(key) {
    const { data } = this.props;
    return (
      <Form.Field inline>
        <Label id={`form.label.${key}`} style={{ width: '370px' }} />
        <div style={{ display: 'inline-block', width: '100%', maxWidth: '300px' }}>
          <Field
            editable={this.editable}
            element={TextArea}
            value={get(data, key, '')}
            style={{ minHeight: '150px' }}
            onChange={(event, data) => this.handleChange(key, data.value)}
          />
        </div>
      </Form.Field>
    );
  }

  renderGuestsField() {
    return (
      <Grid.Row>
        <Grid.Column>
          <Label required id="form.label.guests" style={{ width: '370px' }} />
        </Grid.Column>
        <Grid.Column>
          { this.renderPaxField('adults') }

          { this.renderPaxField('children') }

          { this.renderPaxField('infants') }
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderDatesField() {
    const { errors, data } = this.props;
    const connector = (get(data, 'stay.from') && get(data, 'stay.to')) ? '-' : '';
    return (
      <Grid.Row>
        <Grid.Column>
          <Label required id="form.label.checkInOut" style={{ width: '370px' }} />
        </Grid.Column>
        <Grid.Column>
          <div style={{ display: 'inline-block', marginRight: '45px' }}>
            <Field
              readOnly
              editable={this.editable}
              element={Form.Input}
              style={{ minWidth: '210px' }}
              value={`${get(data, 'stay.from', '')} ${connector} ${get(data, 'stay.to', '')}`}
              error={errors.includes('stay.from') || errors.includes('stay.to')}
              onClick={() => this.handleDatepickerOpen()}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderErrorMessage() {
    const { errors, intl: { formatMessage } } = this.props;
    const isInvalidDate = errors.includes('Invalid date range');
    return (
      <Grid.Row>
        <Message
          error
          header={formatMessage({ id: 'form.error.required.title' })}
          content={formatMessage({ id: `form.error.${isInvalidDate ? 'invalidDate' : 'required'}.message` })}
        />
      </Grid.Row>
    );
  }

  render() {
    const { allowCleaningOption, bedConfigs, errors, roomNames, tenantId } = this.props;

    const roomOptions = this.getOptions(roomNames);
    const bedConfigOptions = this.getOptions(bedConfigs, { includeDecideLater: true });
    const cleaningOptions = this.getOptions(CLEANING_OPTIONS, { shouldFormatMessage: true, includeDecideLater: true, includeDefault: true });
    const paymentOptions = this.getOptions(PAYMENT_OPTIONS, { shouldFormatMessage: true });
    const storageKeyOptions = this.getOptions(STORAGE_KEY_OPTIONS, { shouldFormatMessage: true });
    const serviceOptions = this.getOptions(SERVICE_OPTIONS, { shouldFormatMessage: true });

    return (
      <Form error={errors.length > 0} style={{ lineHeight: '35px', padding: '10px' }}>
        <Grid stackable columns={2}>
          { this.renderDropdownField('room', roomOptions, true, false, `form.requestBooking.notes.${tenantId}.interconnectedApartments`) }

          { this.renderDatesField() }

          { this.renderGuestsField() }

          { this.renderDropdownField('bedConfiguration', bedConfigOptions, false) }

          { allowCleaningOption && this.renderDropdownField('cleaning', cleaningOptions, false) }

          { this.renderDropdownField('ownerStorageKey', storageKeyOptions, false) }

          { this.renderDropdownField('guestServices', serviceOptions, false, true) }

          { this.renderDropdownField('paymentMethod', paymentOptions) }

          { this.renderTextAreaField('otherRequests') }

          {/* Error message will shown when there are required fields that has not filled in; it is controlled from the Form's prop 'error' */}
          { this.renderErrorMessage() }

          <Grid.Row>
            <Grid.Column>
              {/* Display a back button if a callback is supplied */}
              {typeof this.props.onBack === 'function' && (
                <ThemeButton
                  titleId="general.back"
                  onClick={event => this.handleBack(event)}
                  style={{ marginTop: '20px', marginRight: '20px' }}
                />
              )}
              <ThemeButton
                titleId="general.submit"
                onClick={event => this.handleSubmit(event)}
                style={{ marginTop: '20px' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default injectIntl(RequestBookingForm);
