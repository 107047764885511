import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Icon, Message, Table } from 'semantic-ui-react';

import EntityDetailsFormField from './EntityDetailsFormField';
import ThemeLinkButton from '../Theme/ThemeLinkButton';
import ThemeButton from '../Theme/ThemeButton';

export const FIELD_TYPES = {
  inputText: 'inputText',
  dropdownList: 'dropdownList',
  status: 'status',
  textArea: 'textArea',
};

const EntityDetailsForm = ({
  children,
  cleanSubmit,
  confirmationTitle,
  data,
  displayTopUpdateButton,
  enableUpdateView,
  fields,
  labelIds,
  showFullDetailsConfirmation,
  updateViewNotes,
  warningMessage,
  onCancel,
  onChange,
  onSave,
}) => {
  const { formatMessage } = useIntl();
  const [isConfirmView, setIsConfirmView] = useState(false);
  const [updateView, setUpdateView] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});

  const formData = useMemo(() => ({ ...data, ...updatedValues }), [data, updatedValues]);
  const isDirty = Object.keys(updatedValues).length > 0;
  const hasRequiredMissing = isDirty && fields.some(f => f.required && !formData[f.name]);
  const isUpdateView = updateView || enableUpdateView;
  const updateButtonVisible = !isUpdateView && !isConfirmView && typeof onSave === 'function';

  // All the specified data fields are displayed in the Read and Edit views
  // The confirmation view will only display the data fields that have been modified
  const displayFields = !isConfirmView || showFullDetailsConfirmation ? fields : Object.keys(updatedValues).map(key => fields.find(f => f.name === key));

  const resetForm = () => {
    setIsConfirmView(false);
    setUpdateView(false);
    setUpdatedValues({});
  };

  useEffect(() => {
    // Validate all fields when switching to the edition view
    if (isUpdateView) {
      fields.forEach(field => {
        if (typeof field?.validation?.validator === 'function') {
          field.validation.validator(formData[field.name]);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateView]);

  useEffect(() => {
    if (isUpdateView || isConfirmView) {
      resetForm();
    }
  // We want to reset from updated details to the read view if the ID was changed (it means a new instance was passed in)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id]);

  const handleBlur = field => {
    if (typeof field?.validation?.validator === 'function') {
      field.validation.validator(formData[field.name]);
    }
  };

  const handleClick = field => {
    if (typeof field.onClick === 'function') {
      field.onClick();
    }
  };

  const handleChange = (field, value) => {
    setUpdatedValues(prevValues => ({ ...prevValues, [field.name]: value }));
    if (typeof field.onChange === 'function') {
      field.onChange(value);
    }
  };

  const handleCancelClicked = () => {
    resetForm();
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  const handleSubmitClicked = () => {
    setUpdateView(false);
    setIsConfirmView(true);
    if (typeof onChange === 'function') {
      onChange({ id: data.id, ...updatedValues });
    }
  };

  const handleSubmitConfirmedClicked = () => {
    onSave({ id: data.id, ...updatedValues });
    resetForm();
  };

  const handleUpdateClicked = () => {
    setUpdateView(true);
  };

  // Render the whole form
  return (
    <Form>
      {
        isConfirmView && !!confirmationTitle && (
          <div style={{ fontSize: '20px', padding: '10px', marginBottom: '10px' }}>
            {formatMessage({ id: 'page.user.myDetails.updateMessage' })}
          </div>
        )
      }
      <Table
        basic="very"
        compact={isUpdateView}
        style={
          isConfirmView ? { padding: '10px' } : { margin: '0 auto', maxWidth: '1110px' }
        }
      >
        <Table.Body>
          {
            updateButtonVisible && displayTopUpdateButton && (
              <Table.Row>
                <Table.Cell>
                  <ThemeLinkButton
                    label={formatMessage({ id: "general.updateDetails" })}
                    onClick={handleUpdateClicked}
                  />
                </Table.Cell>
              </Table.Row>
            )
          }
          {
            displayFields.map(field => (
              <EntityDetailsFormField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                key={field.name}
                readOnly={!isUpdateView}
                value={formData[field.name]}
                onBlur={() => handleBlur(field)}
                onClick={() => handleClick(field)}
                onChange={value => handleChange(field, value)}
              />
            ))
          }
        </Table.Body>
      </Table>
      {
        children
      }
      {/* Update Button */}
      {
        updateButtonVisible && (
          <ThemeButton
            titleId={labelIds && labelIds.buttons && labelIds.buttons.update ? labelIds.buttons.update : 'general.update'}
            onClick={handleUpdateClicked}
          />
        )
      }
      {/* Cancel Button */}
      {
        (isUpdateView || isConfirmView) && (
          <ThemeButton
            titleId={labelIds && labelIds.buttons && labelIds.buttons.cancel ? labelIds.buttons.cancel : 'general.cancel'}
            onClick={handleCancelClicked}
          />
        )
      }
      {/* Save Button */}
      {
        isUpdateView && (
          <ThemeButton
            disabled={(!isDirty && !cleanSubmit) || hasRequiredMissing}
            titleId={labelIds && labelIds.buttons && labelIds.buttons.submit ? labelIds.buttons.submit : 'general.submit'}
            onClick={handleSubmitClicked}
          />
        )
      }
      {/* Confirm Save Button */}
      {
        isConfirmView && (
          <ThemeButton
            titleId={labelIds && labelIds.buttons && labelIds.buttons.confirm ? labelIds.buttons.confirm : 'general.request'}
            onClick={handleSubmitConfirmedClicked}
          />
        )
      }
      {/* Update View Notes */}
      {
        isUpdateView && (
          updateViewNotes.map(note => (
            <div style={{ color: 'red', marginTop: '1em' }}>
              {note}
            </div>
          ))
        )
      }
      {/* Confirmation View Notes */}
      {
        isConfirmView && warningMessage && (
          <Message warning style={{ display: 'flex' }}>
            {/* ^ Visible prop in Message set the display property to block.
               Since we are overriding the display property, we are not setting the visible prop.
            */}
            <Icon name="exclamation circle" color="red" size="large" />
            <span style={{ fontWeight: 700 }}>
              {warningMessage}
            </span>
          </Message>
        )
      }
    </Form>
  );
};

EntityDetailsForm.propTypes = {
  children: PropTypes.node,
  // cleanSubmit: tells if values can be submitted even without any applied change (when form is not dirty)
  cleanSubmit: PropTypes.bool,
  confirmationTitle: PropTypes.string,
  data: PropTypes.object,
  displayTopUpdateButton: PropTypes.bool,
  enableUpdateView: PropTypes.bool,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      noteSymbol: PropTypes.string,
      translationId: PropTypes.string.isRequired,
      validation: PropTypes.shape({
        failed: PropTypes.bool,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        validator: PropTypes.func,
      }),
    }).isRequired
  ).isRequired,
  labelIds: PropTypes.shape({
    buttons: {
      cancel: PropTypes.string,
      confirm: PropTypes.string,
      submit: PropTypes.string,
      update: PropTypes.string,
    },
  }),
  showFullDetailsConfirmation: PropTypes.bool,
  warningMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  updateViewNotes: PropTypes.arrayOf(PropTypes.string),
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

EntityDetailsForm.defaultProps = {
  cleanSubmit: false,
  data: {},
  displayTopUpdateButton: false,
  enableUpdateView: false,
  labelIds: {},
  updateViewNotes: [],
  showFullDetailsConfirmation: false,
};

export default EntityDetailsForm;
