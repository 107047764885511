import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { logout } from 'redux/modules/auth';
import { connect } from 'react-redux';
import { Container, Grid, Header } from 'semantic-ui-react';
import { Logo, UserStatus } from 'components';
import './Header.css';

@connect(state => ({ user: state.auth.user, tenant: state.tenant }), { logout })
export default class HeaderWrapper extends PureComponent {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    user: PropTypes.object,
    tenant: PropTypes.object.isRequired,
  };

  handleLogout() {
    this.props.logout();
  }

  render() {
    const { user, tenant: { id, theme: { colors, maxWidth } } } = this.props;
    // Ki Niseko Header
    if (id === 'kiniseko') {
      return (
        <Container style={{ background: colors.secondary, width: '100%' }}>
          <Grid style={{ maxWidth: `${maxWidth}px`, margin: '0 auto' }}>
            <Grid.Row columns={2} style={{ padding: '0px' }}>
              <Grid.Column width={2}>
                <Logo tenant={id} inverted style={{ maxHeight: '100px', margin: '10px', padding: '5px' }} />
              </Grid.Column>
              <Grid.Column width={7} verticalAlign="middle">
                <Header as="h2">
                  <FormattedMessage id="header.title" />
                </Header>
              </Grid.Column>
              <Grid.Column width={7}>
                { user && <UserStatus name={user.username} logout={() => this.handleLogout()} /> }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }
    // Niseko Central Header
    if (id === 'nisekocentral') {
      return (
        <Container style={{ background: colors.secondary, width: '100%' }}>
          <Grid style={{ maxWidth: `${maxWidth}px`, margin: '0 auto' }}>
            <Grid.Row columns={2} style={{ padding: '0' }}>
              <Grid.Column width={3}>
                <Logo tenant={id} inverted style={{ padding: '20px', maxWidth: '200px' }} />
              </Grid.Column>
              <Grid.Column width={6} verticalAlign="middle">
                <Header as="h2" inverted>
                  <FormattedMessage id="header.title" />
                </Header>
              </Grid.Column>
              <Grid.Column width={7}>
                { user && <UserStatus inverted name={user.username} logout={() => this.handleLogout()} /> }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }
    // Skye Header
    if (id === 'skye') {
      return (
        <Container style={{ background: colors.secondary, width: '100%' }}>
          <div className="bar" />
          <Grid style={{ maxWidth: `${maxWidth}px`, margin: '0 auto' }}>
            <Grid.Row columns={2} style={{ padding: '0' }}>
              <Grid.Column width={3}>
                <Logo tenant={id} inverted src="/logo.svg" style={{ padding: '40px', maxWidth: '200px' }} />
              </Grid.Column>
              <Grid.Column width={6} verticalAlign="middle">
                <Header as="h2">
                  <FormattedMessage id="header.title" />
                </Header>
              </Grid.Column>
              <Grid.Column width={7}>
                { user && <UserStatus name={user.username} logout={() => this.handleLogout()} /> }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      );
    }
    return null;
  }
}
