import size from 'lodash/size';
import { display } from 'redux/modules/dialog';

export default ({ dispatch }) => next => action => {
  // Don't show the error dialog when it is handling the phone number.
  if (action?.error?.message === 'Invalid phone number') {
    next(action);
    return;
  }

  if (size(action.error) > 0) {
    const { type, message } = action.error;
    dispatch(display(type, message, 'error'));
  }
  else {
    next(action);
  }
};
