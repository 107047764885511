import types from './types';

const {
  RESET,
  LOAD, LOAD_SUCCESS, LOAD_FAIL,
  LOAD_OWNER_BOOKINGS, LOAD_OWNER_BOOKINGS_SUCCESS,
  LOAD_ROOM_BOOKINGS, LOAD_ROOM_BOOKINGS_SUCCESS,
} = types;

const initialState = {
  loading: false,
  loaded: false,
  ownerBookings: [],
  roomBookings: [],
  error: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case LOAD_OWNER_BOOKINGS:
      return {
        ...state,
        loading: true,
        loaded: false,
        ownerBookings: [],
        error: {},
      };
    case LOAD_OWNER_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        ownerBookings: action.result,
      };
    case LOAD_ROOM_BOOKINGS:
      return {
        ...state,
        loading: true,
        loaded: false,
        roomBookings: [],
        error: {},
      };
    case LOAD_ROOM_BOOKINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        roomBookings: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case RESET:
      return { ...initialState };
    default:
      return state;
  }
}
