import isAdmin from './isAdmin';

export default function parseLoggedInUser(user) {
  // If admin has successfully verified the security key
  const userIsAdmin = isAdmin(user);
  return {
    user: userIsAdmin ? null : user,
    admin: userIsAdmin ? user : null,
  };
}
