import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { display } from 'redux/modules/dialog';

// Global notification handler that works with notification middleware to display notifications
export class NotificationManager extends PureComponent {
  static propTypes = {
    display: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { notifications, intl: { formatMessage }, display } = this.props;
    if (notifications.length !== prevProps.notifications.length) {
      const latestNotification = notifications[notifications.length - 1];
      display(
        formatMessage({ id: 'notifications.title.success' }),
        formatMessage({ id: latestNotification.messageId }),
        'success'
      );
    }
  }

  render() {
    return null;
  }
}

const withIntl = injectIntl(NotificationManager);

export default connect(state => ({ notifications: state.notifications }), { display })(withIntl);
