import types from './types';

const {
  CREATE, CREATE_SUCCESS, CREATE_FAIL,
  LOAD, LOAD_SUCCESS, LOAD_FAIL,
  UPDATE, UPDATE_SUCCESS, UPDATE_FAIL,
  RESET,
} = types;

export function clearStore() {
  return { type: RESET };
}

export function getOwnerBankAccounts(ownerId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get(`/OwnerBankAccounts/${ownerId}`),
  };
}

export function saveBankAccount(ownerId, bankAccount) {
  const notifications = [null, 'notifications.saveBankAccount.successful', null];
  const { id, ...data } = bankAccount;

  if (bankAccount.id) {
    // It's an existing bank account to be updated
    return {
      types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
      notifications,
      promise: client => client.patch(`/OwnerBankAccounts/${ownerId}/${id}`, { data }),
    };
  }
  // It's a new bank account to be created
  return {
    types: [CREATE, CREATE_SUCCESS, CREATE_FAIL],
    notifications,
    promise: client => client.post(`/OwnerBankAccounts/${ownerId}`, { data }),
  };
}
