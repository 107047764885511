import PropTypes from 'prop-types';
import React from 'react';

const ToolTips = ({ title, children }) => (
  <span title={title} style={{ display: 'inline-block' }}>
    {children}
  </span>
);

ToolTips.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ToolTips;
