import types from './types';

const {
  RESET,
  LOAD, LOAD_SUCCESS, LOAD_FAIL,
  LOAD_OWNER_BOOKINGS, LOAD_OWNER_BOOKINGS_SUCCESS,
  LOAD_ROOM_BOOKINGS, LOAD_ROOM_BOOKINGS_SUCCESS,
} = types;

export function clearStore() {
  return { type: RESET };
}

export function requestBooking(userId, data, tenant, token) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    notifications: [null, 'notifications.requestBooking.successful', null],
    promise: client => client.post(`/owners/${userId}/requestBooking`, { data, tenant, token }),
  };
}

export function getOwnerBookings(ownerId, token) {
  return {
    types: [LOAD_OWNER_BOOKINGS, LOAD_OWNER_BOOKINGS_SUCCESS, LOAD_FAIL],
    promise: client => client.get(`/owners/${ownerId}/getOwnerBookings`, { token }),
  };
}

export function getRoomBookings(roomId, contractId) {
  const params = { contractId };
  return {
    types: [LOAD_ROOM_BOOKINGS, LOAD_ROOM_BOOKINGS_SUCCESS, LOAD_FAIL],
    promise: client => client.get(`/owners/getRoomBookings/${roomId}`, { params }),
  };
}

export function getRoomTypeStatistics(tenantId) {
  return {
    types: [LOAD_ROOM_BOOKINGS, LOAD_ROOM_BOOKINGS_SUCCESS, LOAD_FAIL],
    promise: client => client.get(`/owners/getRoomTypeStatistics/${tenantId}`),
  };
}
