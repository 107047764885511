import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const SelectOwnerId = loadable(() => import(/* webpackChunkName: "SelectOwnerId" */ './SelectOwnerId'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncSelectOwnerId = ({ route }) => (
  <SelectOwnerId route={route} />
);

asyncSelectOwnerId.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncSelectOwnerId;
