import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';

@connect(state => ({ client: state.client }))
export default class TableWrapper extends Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
    body: PropTypes.array.isRequired,
    footer: PropTypes.array,
  };

  render() {
    const { client, headers, body, footer } = this.props;
    // Mobile view table
    // Tables in screenwidth from 700 to 768 behaved abnormally in tablet mode. So that range is mixed with mobile mode.
    if (client.screenType === 'mobile' || client.screenWidth < '768') {
      return (
        <Table basic="very" size="large" style={{ maxWidth: '1100px', margin: '20px auto' }}>
          <Table.Body>
            {
              body.map(row => (
                <Table.Row key={row.id}>
                  {
                    Array.from(Array(row.content.length)).map((value, index) => (
                      <Table.Cell key={headers[index].id} style={{ marginTop: '10px', background: index === 0 ? '#ffefd5' : undefined }}>
                        <strong>{headers[index].content}</strong>
                        :
                        {row.content[index]}
                      </Table.Cell>
                    ))
                  }
                </Table.Row>
              ))
            }
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              {
                footer && Array.from(Array(footer.length)).map((value, index) => (
                  <Table.Cell key={footer[index].id} style={{ fontWeight: 'bold', marginTop: '10px' }}>
                    {footer[index].title || (
                    <span>
                      <FormattedMessage id="general.total" />
                      {headers[footer[index].index].content}
                    </span>
                    )}
                    :
                    {footer[index].content}
                  </Table.Cell>
                ))
              }
            </Table.Row>
          </Table.Footer>
        </Table>
      );
    }

    return (
      <Table basic="very" size="large" style={{ maxWidth: '1100px', margin: '20px auto' }}>
        <Table.Header>
          <Table.Row>
            { headers.map(header => <Table.HeaderCell width={header.width} key={header.id}>{header.content}</Table.HeaderCell>) }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            body.map(row => (
              <Table.Row key={row.id}>
                { row.content.map((cell, index) => <Table.Cell key={`${headers[index].id}${cell}`}>{cell}</Table.Cell>) }
              </Table.Row>
            ))
          }
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            {
              footer && footer.map(f => (
                <Table.Cell key={f.id} colSpan={f.colSpan} style={{ fontWeight: 'bold' }}>
                  {f.title}
                  {f.title ? ': ' : ''}
                  {f.content}
                </Table.Cell>
              ))
            }
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}
