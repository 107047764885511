import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const RequestBooking = loadable(() => import(/* webpackChunkName: "RequestBooking" */ './RequestBooking'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncRequestBooking = ({ route }) => (
  <RequestBooking route={route} />
);

asyncRequestBooking.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncRequestBooking;
