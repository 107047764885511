import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const ForgotPassword = loadable(() => import(/* webpackChunkName: "ForgotPassword" */ './ForgotPassword'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncForgotPassword = ({ route }) => (
  <ForgotPassword route={route} />
);

asyncForgotPassword.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncForgotPassword;
