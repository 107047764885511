const types = {
  CHANGE_PASSWORD: 'app/activation/CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'app/activation/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL: 'app/activation/CHANGE_PASSWORD_FAIL',
  RESET_PASSWORD: 'app/activation/RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'app/activation/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'app/activation/RESET_PASSWORD_FAIL',
  FORGOT_PASSWORD: 'app/activation/FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'app/activation/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAIL: 'app/activation/FORGOT_PASSWORD_FAIL',
  VERIFY: 'app/activation/VERIFY',
  VERIFY_SUCCESS: 'app/activation/VERIFY_SUCCESS',
  VERIFY_FAIL: 'app/activation/VERIFY_FAIL',
  CLEAN_UP: 'app/activation/CLEAN_UP',
};

export default types;
