/* eslint-disable import/no-import-module-exports */
/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import { shouldPolyfill } from '@formatjs/intl-listformat/should-polyfill';

import ChildRoutes from 'helpers/ChildRoutes';
import { domClassSanitizer, requestSanitizer, responseSanitizer } from 'logRocketConfig';
import ApiClient from './helpers/ApiClient';
import createStore from './redux/create';
import constants from './constants';
import routes from './routes';

const LOGROCKET_ID = process.env.NODE_ENV === 'production' ? constants.LogRocketProd : constants.LogRocketDev;
/* eslint-disable-next-line */
import LogRocket from 'logrocket';
LogRocket.init(LOGROCKET_ID, {
  dom: {
    inputSanitizer: true,
    privateAttributeBlocklist: domClassSanitizer,
    privateClassNameBlocklist: domClassSanitizer,
  },
  network: {
    requestSanitizer,
    responseSanitizer,
  },
});

// Set up all the initial stuff
const localeCode = window.location.pathname.startsWith('/ja') ? 'ja' : 'en';
// eslint-disable-next-line no-underscore-dangle
const initialState = window.__INITIAL_STATE__;
const client = new ApiClient();
client.configure({ locale: localeCode });

// Update date display format for both locales
moment.updateLocale('en', constants.momentDateFormats.en);
moment.updateLocale('ja', constants.momentDateFormats.ja);
moment.locale(localeCode);

// Check if PluralRules is available, old browsers IE11, Chrome v.<=68 and Safari doesn't support it
if (!Intl.PluralRules) {
  // Enable the PluralRules polyfill
  // eslint-disable-next-line global-require
  require('@formatjs/intl-pluralrules/polyfill');
  // eslint-disable-next-line global-require
  require('@formatjs/intl-pluralrules/locale-data/en');
  // eslint-disable-next-line global-require
  require('@formatjs/intl-pluralrules/locale-data/ja');
}

const { store, history } = createStore({ initialState, client });

function render(Routes) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ChildRoutes routes={Routes} />
      </ConnectedRouter>
    </Provider>,
    document.getElementById('content')
  );
}

// loadable-component setup
loadableReady(() => {
  render(routes);
});

// Polyfill the formatList API if the current user's browser doesn't support it
if (shouldPolyfill(localeCode)) {
  // eslint-disable-next-line global-require
  require('@formatjs/intl-listformat/polyfill-force');
  // eslint-disable-next-line
  require(`@formatjs/intl-listformat/locale-data/${localeCode}`);
}

if (module.hot) {
  // Enable webpack hot module replacement for routes
  module.hot.accept('./routes', () => {
    try {
      // eslint-disable-next-line global-require
      const nextRoutes = require('./routes').default;

      render(nextRoutes);
    }
    catch (error) {
      console.error(`==> ⚠  Routes hot reloading error ${error}`);
    }
  });
}
