import Helmet from 'react-helmet';
import React from 'react';
import { useIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';

import ThemeHeader from 'components/Theme/ThemeHeader';
import BankAccountsContainer from 'containers/User/BankAccounts/BankAccounts';
import { TITLE_ID } from './constants';

const BankAccounts = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Helmet title={formatMessage({ id: TITLE_ID })} />
      <ThemeHeader titleId={TITLE_ID} />
      <BankAccountsContainer />
    </Container>
  );
};

export default BankAccounts;
