import types from './types';

const {
  RESET,
  LOAD, LOAD_SUCCESS, LOAD_FAIL,
} = types;

export function clearStore() {
  return { type: RESET };
}

export function loadContracts(userId, params) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get(`/owners/${userId}/getOwnerContracts`, { params }),
  };
}

export function loadActiveContracts(userId, params) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get(`/owners/${userId}/getOwnerActiveContracts`, { params }),
  };
}
