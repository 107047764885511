import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const MyDetails = loadable(() => import(/* webpackChunkName: "MyDetails" */ './MyDetails'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncMyDetails = ({ route }) => (
  <MyDetails route={route} />
);

asyncMyDetails.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncMyDetails;
