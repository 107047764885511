import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const Occupancy = loadable(() => import(/* webpackChunkName: "Occupancy" */ './Occupancy'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncOccupancy = ({ route }) => (
  <Occupancy route={route} />
);

asyncOccupancy.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncOccupancy;
