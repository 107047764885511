import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatNumber, round } from 'utils/misc';
import { Header, Table } from 'semantic-ui-react';

export default class Balance extends Component {
  static propTypes = {
    balance: PropTypes.object,
    property: PropTypes.object,
    children: PropTypes.node.isRequired,
  };

  get outdatedWarning() {
    const { balance } = this.props;
    // NOTE Here we assume that there will only be one contract for the same property for one owner
    // which if there are two contracts existed, only the first found will be returned.
    const contract = balance?.property?.contracts?.find(c => c.ownerId === balance.ownerId);
    // Check if the contract has expired more than 6 months ago
    const isInvalid = contract && moment().diff(contract.ownerTo, 'months') > 6;
    return isInvalid && (
      <Header.Subheader style={{ color: '#eb630d' }}>
        <FormattedMessage id="page.financial.requestPayment.outdatedBalance" />
      </Header.Subheader>
    );
  }

  get property() {
    const { balance, property } = this.props;
    const strata = balance ? balance.property?.strata ?? '' : property?.strata ?? '';
    const name = balance ? balance.property?.name ?? '' : property?.name ?? '';
    return (
      <Header as="h2">
        {strata}
        <Header.Subheader>
          {name}
        </Header.Subheader>
      </Header>
    );
  }

  get balance() {
    const { balance } = this.props;
    const amount = balance ? formatNumber(round(balance.amount), { style: 'currency', precision: 0 }) : '';

    return (
      <Header as="h2">
        {amount}
        <Header.Subheader>
          <FormattedMessage
            id={`page.financial.requestPayment.${balance ? 'balance' : 'noBalance'}`}
            values={{ date: balance && moment.utc(balance.validAsAt).local().format('l') }}
          />
        </Header.Subheader>
        { balance && this.outdatedWarning }
      </Header>
    );
  }

  render() {
    const { children } = this.props;

    return (
      <Table.Row>
        <Table.Cell>
          {this.property}
        </Table.Cell>
        <Table.Cell width={8}>
          {this.balance}
        </Table.Cell>
        <Table.Cell width={5}>
          { children }
        </Table.Cell>
      </Table.Row>
    );
  }
}
