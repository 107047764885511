import types from './types';

const { ADD, CLEAR } = types;

export function add(event) {
  return { type: ADD, event };
}

export function clear() {
  return { type: CLEAR };
}
