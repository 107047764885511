import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import Input from './Input';

const ReduxField = ({ id, ...rest }) => (
  <Field
    id={id}
    name={id}
    component={Input}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

ReduxField.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ReduxField;
