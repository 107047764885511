/* eslint import/prefer-default-export: "off" */
export function getMessage(errorObj) {
  // Check if it's proper error from API
  if ({}.hasOwnProperty.call(errorObj, 'status') && !errorObj.status) {
    if (errorObj.errors.length > 1) {
      console.warn('Error has more than one errors => Add handling!', errorObj);
    }
    const error = errorObj.errors[0];
    return {
      status: error.code,
      message: error.message,
      type: error.type,
    };
  }

  // Might also be an error that actual connection to the API failed
  if ({}.hasOwnProperty.call(errorObj, 'error')) {
    let { error } = errorObj;
    // TODO A temporary fix for even-deeper nested errors. Gateway should handle this better
    if (typeof error.message === 'object') {
      error = getMessage(error.message);
    }
    return {
      status: error.status,
      message: error.message,
      type: 'Just a slight problem...',
    };
  }

  // If it's none of the above, it hasn't come across yet.
  console.warn('Unhandled error type! See utils/errors.js and add handling for this', errorObj);
  return errorObj;
}
