import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import { intlReducer } from 'react-intl-redux';
import { reducer as facetReducer } from '@htmniseko/htm-components/Facet';
import { reducer as clientReducer } from '@htmniseko/htm-components/ClientManager';
import { reducer as datepicker } from '@htmniseko/htm-ui/DatePicker';
import activation from './activation';
import app from './app';
import auth from './auth';
import balance from './balance';
import bankAccount from './bankAccount';
import booking from './booking';
import contract from './contract';
import contact from './contact/reducers';
import dialog from './dialog';
import history from './history';
import notifications from './notifications';
import payment from './payment';
import popup from './popup';
import tenant from './tenant';

export default routerHistory => combineReducers({
  router: connectRouter(routerHistory),
  intl: intlReducer,
  form,

  activation,
  app,
  auth,
  balance,
  bankAccount,
  booking,
  contract,
  contact,
  client: clientReducer,
  datepicker,
  dialog,
  history,
  facets: facetReducer,
  notifications,
  payment,
  popup,
  tenant,
});
