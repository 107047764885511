import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Form } from 'semantic-ui-react';

const DropdownWrapper = ({ labelId, onChange, options, value }) => {
  const handleDropdownChange = (e, { value: selectedValue }) => {
    onChange(selectedValue);
  };

  return (
    <Form.Field inline>
      {typeof labelId === 'string' && (
        <label
          style={{ fontSize: '1.2em', margin: '20px', marginLeft: '10px' }}
          htmlFor={labelId}
          aria-label={labelId}
        >
          <FormattedMessage id={labelId} />
        </label>
      )}
      <Dropdown
        id={labelId}
        value={value}
        options={options}
        onChange={handleDropdownChange}
      />
    </Form.Field>
  );
};

DropdownWrapper.propTypes = {
  labelId: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
};

export default DropdownWrapper;
