import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

/* eslint-disable no-unused-vars, react/prop-types */
const LocalizedLink = ({ to, children, ...rest }) => {
  const { locale } = useSelector(state => state.intl);

  if (to) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (<Link to={`/${locale}${to}`} {...rest}>{children}</Link>);
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (<Link {...rest}>{children}</Link>);
};

LocalizedLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

export default LocalizedLink;
