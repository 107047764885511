import cookies from './cookies';

export default function getCookie(cookieName) {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);

  // Split the cookie string into an array of strings.
  const cookieArray = decodedCookie.split(';');

  // Go through that array and match the cookie name and return the result.
  const result = cookieArray.map(cookieString => {
    // trim any excess spacing if required.
    const cleanCookie = cookieString.trim();
    // If it matches the cookie
    if (cleanCookie.indexOf(name) === 0) {
      return cleanCookie.substring(name.length, cleanCookie.length);
    }
    return '';
  }).filter(Boolean);

  return result[0] || null;
}

export function getTrustedDeviceCookie() {
  return getCookie(cookies.TrustedDevice);
}
