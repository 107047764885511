import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Label } from 'semantic-ui-react';

@connect(state => ({ aStyles: state.tenant.theme.a }))
export default class ThemeLinkButton extends Component {
  static propTypes = {
    aStyles: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    size: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    size: 'large',
  };

  render() {
    const { aStyles, onClick, size, style, label, disabled } = this.props;
    return (
      <Label
        as="a"
        basic
        size={size}
        onClick={!disabled ? onClick : null}
        style={{
          border: 'none',
          display: 'block',
          cursor: disabled ? 'not-allowed' : 'pointer',
          margin: 0,
          paddingLeft: 0,
          paddingRight: 0,
          ...aStyles,
          ...style,
          color: !disabled ? aStyles.color : 'lightgray',
        }}
      >
        {label}
      </Label>
    );
  }
}
