import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, List } from 'semantic-ui-react';
import { AbsoluteLink, ThemeHeader } from 'components';

import { contacts, links } from '../../constants';

export default class NisekoCentral extends Component {
  render() {
    const email = contacts.email.nisekocentral;

    return (
      <Container style={{ fontSize: '20px', marginBottom: '50px' }}>
        {/* Owner Benefits */}
        <ThemeHeader titleId="page.general.info.benefits.header" />
        <p><FormattedMessage id="page.general.info.benefits.description.nisekocentral" /></p>
        <p><FormattedMessage id="page.general.info.benefits.list.other.nisekocentral" /></p>
        {/* Temporary commented out for now. Offer not valid for the 20/21 season */}
        {/* <List
          bulleted
          items={[
            formatMessage({ id: 'page.general.info.benefits.list.gosnow' }),
            formatMessage({ id: 'page.general.info.benefits.list.liftPasses' }),
            formatMessage({ id: 'page.general.info.benefits.list.equipmentRentals' }),
            formatMessage({ id: 'page.general.info.benefits.list.other.nisekocentral' }),
          ]}
        /> */}
        <p>
          <FormattedMessage id="page.general.info.benefits.link.contact.nisekocentral" />
          &nbsp;
          (
          <a href={`mailto:${email}`}>{email}</a>
          ).
        </p>
        {/* Owner Tax Documents */}
        <ThemeHeader titleId="page.general.info.taxDocuments.header" />
        <p><FormattedMessage id="page.general.info.clickToDownload" /></p>
        <List
          items={
            Object.keys(links.nisekocentral.taxDocuments).map(link => (
              <AbsoluteLink
                key={link}
                link={links.nisekocentral.taxDocuments[link]}
                textId={`page.general.info.taxDocuments.${link}.text`}
                descriptionId={`page.general.info.taxDocuments.${link}.description`}
                style={{ display: 'block', margin: '10px 0' }}
              />
            ))
          }
        />
        <p>
          <AbsoluteLink
            link={links.nisekocentral.downloadAllTaxDocuments}
            textId="page.general.info.taxDocuments.downloadAll"
            style={{ fontStyle: 'italic' }}
          />
        </p>
        {/* Rates for Chargeable Work */}
        <ThemeHeader titleId="page.general.info.chargeableWorkRate.header" />
        <p><FormattedMessage id="page.general.info.clickToDownload" /></p>
        <p>
          <AbsoluteLink link={links.nisekocentral.chargeableWorkRate} textId="page.general.info.chargeableWorkRate.downloadLink" />
          &nbsp;
          <FormattedMessage id="page.general.info.chargeableWorkRate.lastUpdated.nisekocentral" />
        </p>
      </Container>
    );
  }
}
