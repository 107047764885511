import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { clearStore, getOwnerBookings } from 'redux/modules/booking';
import { Dropdown, NoDataFound, Table, ContactUsWidget } from 'components';

export class OwnerBookings extends PureComponent {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    bookingStore: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      loaded: PropTypes.bool.isRequired,
      ownerBookings: PropTypes.array.isRequired,
      error: PropTypes.object.isRequired,
    }),
    clearStore: PropTypes.func.isRequired,
    getOwnerBookings: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  state = {
    room: 'all',
  };

  componentDidMount() {
    const { user, token } = this.props.auth;
    this.props.getOwnerBookings(user.id, token);
  }

  componentWillUnmount() {
    // Clean up redux store when unmounting the tab
    this.props.clearStore();
  }

  handleChange(value) {
    this.setState({ room: value });
  }

  get bookings() {
    const { ownerBookings } = this.props.bookingStore;
    const { room } = this.state;
    // Return if there is no bookings
    if (ownerBookings.length < 1) {
      return [];
    }
    // Return all bookings
    if (room === 'all') {
      return ownerBookings;
    }
    // Filter the selected room
    return ownerBookings.filter(booking => (booking?.room?.id === room));
  }

  renderFilter() {
    const { bookingStore: { ownerBookings }, intl: { formatMessage } } = this.props;
    const bookingRooms = ownerBookings.map(booking => booking?.room);
    const roomIds = uniqBy(bookingRooms, 'id').map(room => ({ text: room.code, key: room.id, value: room.id }));
    const roomOptions = [{ text: formatMessage({ id: 'general.all' }), key: 'all', value: 'all' }].concat(roomIds);

    return (
      <Dropdown
        selection
        options={roomOptions}
        value={this.state.room}
        labelId="page.booking.filter.label.room"
        onChange={value => this.handleChange(value)}
        placeholder={formatMessage({ id: 'general.all' })}
      />
    );
  }

  renderNoBookings() {
    const { formatMessage } = this.props.intl;
    return (
      <NoDataFound
        iconColor="brown"
        iconName="folder open outline"
        message={formatMessage({ id: 'page.booking.label.noBookings' })}
      />
    );
  }

  renderBookings() {
    // Sort bookings by arrival date
    const sortedBookings = this.bookings.sort((a, b) => (new Date(a.arrivalDate) - new Date(b.arrivalDate)));

    const headers = [
      { id: 'folioId', content: <FormattedMessage id="form.label.folioId" /> },
      { id: 'checkIn', content: <FormattedMessage id="form.label.checkIn" /> },
      { id: 'checkOut', content: <FormattedMessage id="form.label.checkOut" /> },
      { id: 'nights', content: <FormattedMessage id="form.label.nights" /> },
      { id: 'room', content: <FormattedMessage id="form.label.room" /> },
      { id: 'name', content: <FormattedMessage id="form.label.name" /> },
      { id: 'adults', content: <FormattedMessage id="form.label.adults" /> },
      { id: 'children', content: <FormattedMessage id="form.label.children" /> },
      { id: 'infants', content: <FormattedMessage id="form.label.infants" /> },
    ];

    const body = sortedBookings.map(booking => {
      const checkIn = moment.utc(booking.arrivalDate);
      const checkOut = moment.utc(booking.departDate);

      let firstName = '';
      let surname = '';
      if (Array.isArray(booking?.guests) && booking.guests.length > 0) {
        ({ firstName, surname } = booking.guests[0]);
      }

      let adults = 0;
      let children = 0;
      let infants = 0;
      if (Array.isArray(booking?.bookedRooms) && booking.bookedRooms.length > 0) {
        ({ adults, children, infants } = booking.bookedRooms[0]);
      }

      return {
        id: booking.id,
        content: [
          booking.id,
          checkIn.format('l'),
          checkOut.format('l'),
          checkOut.diff(checkIn, 'days'),
          booking?.room?.code,
          `${firstName} ${surname}`,
          adults, children, infants,
        ],
      };
    });

    return <Table headers={headers} body={body} />;
  }

  render() {
    const { loaded, ownerBookings, error } = this.props.bookingStore;
    const hasBookings = ownerBookings.length > 0;
    const hasError = Object.keys(error).length > 0;
    return (
      <>
        <div>
          {/* Display message when there is no bookings found */}
          { (hasError || (loaded && !hasBookings)) && this.renderNoBookings() }
          {/* Display dropdown filer and bookings when there is data */}
          { loaded && hasBookings && this.renderFilter() }
          { loaded && hasBookings && this.renderBookings() }
        </div>
        <ContactUsWidget />
      </>
    );
  }
}

const withIntl = injectIntl(OwnerBookings);

export default connect(state => ({ auth: state.auth, bookingStore: state.booking }), { clearStore, getOwnerBookings })(withIntl);
