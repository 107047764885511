import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const HistoricalStatements = loadable(() => import(/* webpackChunkName: "HistoricalStatements" */ './HistoricalStatements'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncHistoricalStatements = ({ route }) => (
  <HistoricalStatements route={route} />
);

asyncHistoricalStatements.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncHistoricalStatements;
