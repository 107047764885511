import types from './types';

const { DISPLAY, HIDE } = types;

export function display(title, text, actionText, actionLink, headerColor, showActionButton = true) {
  return { type: DISPLAY, title, text, actionText, actionLink, headerColor, showActionButton };
}

export function hide() {
  return { type: HIDE };
}
