import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Tab } from 'semantic-ui-react';

import { getOwnerBankAccounts, saveBankAccount } from 'redux/modules/bankAccount';
import EntityDetailsForm from 'components/Form/EntityDetailsForm';
import { BANK_ACCOUNT_FIELDS, BANK_ACCOUNT_TEMPLATES } from './constants';

const BankAccounts = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [warning, setWarning] = useState(null);
  const bankAccounts = useSelector(state => state.bankAccount.bankAccounts);
  const ownerId = useSelector(state => state.auth.user.id);
  const ownerUsed2FA = useSelector(state => state.auth.used2FA);
  const numOfAccounts = Array.isArray(bankAccounts) ? bankAccounts.length : 0;

  useEffect(() => {
    dispatch(getOwnerBankAccounts(ownerId));
  }, [dispatch, ownerId]);

  const handleValuesChanged = updatedValues => {
    if (ownerUsed2FA) {
      // Owners that logged-in with 2FA will get their Bank updates automatically verified
      setWarning(null);
      return;
    }

    const bankAccount = bankAccounts.find(b => b.id === updatedValues.id);
    if (!bankAccount || !bankAccount.verified) {
      // No warning is required for new bank accounts or account that are not yet verified
      setWarning(null);
      return;
    }

    // A verified bank account is going to be updated and the Owner didn't use 2FA
    setWarning(formatMessage({ id: 'page.user.bankAccounts.statusChange.warning' }));
  };

  const handleSaveClicked = bankAccount => {
    dispatch(saveBankAccount(ownerId, bankAccount));
  };

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      panes={
        BANK_ACCOUNT_TEMPLATES.map((template, idx) => {
          if (idx > numOfAccounts) {
            // Only return 1 tab in excess of the number of accounts
            // I.e.:
            //  - If the owner has no Bank Account, then only the first tab is rendered.
            //  - If the owner only has 1 Bank Account, then only the first and the second tab are rendered.
            return null;
          }
          return {
            menuItem: formatMessage({ id: template.translationId }),
            render: () => (
              <EntityDetailsForm
                confirmationTitle={formatMessage({ id: 'page.user.myDetails.updateMessage' })}
                data={bankAccounts.find(account => account.ownerNumber === template.orderNumber)}
                displayTopUpdateButton
                fields={BANK_ACCOUNT_FIELDS}
                labelIds={{ buttons: { confirm: 'general.confirm' } }}
                warningMessage={warning}
                onChange={handleValuesChanged}
                onSave={handleSaveClicked}
              />
            ),
          };
        })
      }
    />
  );
};

export default BankAccounts;
