import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const AccessDenied = loadable(() => import(/* webpackChunkName: "AccessDenied" */ './AccessDenied'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncAccessDenied = ({ route }) => (
  <AccessDenied route={route} />
);

asyncAccessDenied.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncAccessDenied;
