import types from './types';

const {
  VERIFY, VERIFY_SUCCESS, VERIFY_FAIL,
  CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL,
  RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL,
  FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAIL,
  CLEAN_UP,
} = types;

export function cleanup() {
  return { type: CLEAN_UP };
}

export function verify(uid, token) {
  return {
    types: [VERIFY, VERIFY_SUCCESS, VERIFY_FAIL],
    promise: client => client.get('/staff/confirm2', { params: { uid, token } }),
  };
}

export function changePassword(oldPassword, newPassword) {
  return {
    types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
    promise: client => client.post('/owners/change-password2', { data: { oldPassword, newPassword } }),
  };
}

export function resetPassword(password, token) {
  return {
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
    promise: client => client.post('/owners/reset-password2', { data: { password }, token }),
  };
}

export function requestPasswordReset(email, tenant) {
  return {
    types: [FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAIL],
    notifications: [null, 'notifications.forgotPassword.emailSent', null],
    promise: client => client.post('/owners/forgot-password', { data: { email }, tenant }),
  };
}
