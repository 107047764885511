import React from 'react';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const ContactUs = loadable(() => import(/* webpackChunkName: "ContactUs" */ './ContactUs'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncContactUs = () => (
  <ContactUs />
);

export default asyncContactUs;
