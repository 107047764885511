import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';

@connect(state => ({ buttonStyles: state.tenant.theme.button }))
export default class ThemeButton extends Component {
  static propTypes = {
    buttonStyles: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    fluid: PropTypes.bool,
    iconName: PropTypes.string,
    onClick: PropTypes.func,
    titleId: PropTypes.string.isRequired,
    size: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    disabled: false,
    fluid: false,
    size: 'large',
  };

  render() {
    const { buttonStyles, disabled, fluid, iconName, onClick, size, style, titleId } = this.props;
    return (
      <Button
        size={size}
        fluid={fluid}
        onClick={onClick}
        disabled={disabled}
        style={{ ...buttonStyles, ...style }}
      >
        { iconName && <Icon name={iconName} /> }
        <FormattedMessage id={titleId} />
      </Button>
    );
  }
}
