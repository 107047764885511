import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Label = props => {
  const { id, required, style } = props;
  const defaultStyles = { fontSize: '1.2em', width: '265px', display: 'inline-block', fontWeight: 'bold', paddingRight: '20px' };
  return (
    <label style={{ ...defaultStyles, ...style }} htmlFor={id}>
      <FormattedMessage id={id} />
      { required && <span style={{ color: '#ff5400' }}>*</span> }
    </label>
  );
};

Label.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  style: PropTypes.object,
};
Label.defaultProps = {
  required: false,
  style: {},
};

export default Label;
