/* eslint-disable import/no-import-module-exports */
import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import createMiddleware from './middleware/clientMiddleware';
import errorMiddleware from './middleware/errorMiddleware';
import notificationMiddleware from './middleware/notificationMiddleware';
import appMiddleware from './modules/app/middleware';
import authMiddleware from './modules/auth/middleware';
import createRootReducer from './modules/reducer';

export default ({ initialState, client, url }) => {
  // Create a history depending on the environment
  const history = __CLIENT__ ? createBrowserHistory() : createMemoryHistory({ initialEntries: [url || '/'] });
  // Create Redux middlewares (Note that the order matters)
  const middleware = [
    createMiddleware(client),
    routerMiddleware(history),
    errorMiddleware,
    notificationMiddleware,
    authMiddleware,
    appMiddleware,
  ];

  let finalCreateStore;
  // Build the store
  if ((process.env.NODE_ENV !== 'production') && __CLIENT__) {
    // Enabled Redux-Dev-Tools for development and test environments
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle
    finalCreateStore = composeEnhancers(
      applyMiddleware(...middleware)
    )(createStore);
  }
  else {
    // Build the store for production without redux dev tools
    finalCreateStore = applyMiddleware(...middleware)(createStore);
  }

  // Create the store
  const store = finalCreateStore(
    createRootReducer(history),
    initialState || {},
  );

  // Hot module replacement for development
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./modules/reducer', () => {
      try {
        // eslint-disable-next-line global-require
        const createNextReducer = require('./modules/reducer').default;
        store.replaceReducer(createNextReducer(history));
      }
      catch (error) {
        console.error(`==> 😭  Reducer hot reloading error ${error}`);
      }
    });
  }

  return { store, history };
};
