/* Tenants Block ID */
const NC_BLOCK_ID = 1;
const KN_BLOCK_ID = 6;
const SN_BLOCK_ID = 7;

module.exports = {
  /* Custom date formats for moment library */
  momentDateFormats: {
    en: {
      longDateFormat: {
        l: 'D MMM YYYY',
        ll: 'ddd D MMM',
        LLL: 'dddd, Do MMMM',
        LLLL: 'Do MMM YYYY (dddd)',
      },
    },
    ja: {
      longDateFormat: {
        l: 'YYYY年M月D日',
        ll: 'M月D日(ddd)',
        LLL: 'M月D日(dddd)',
        LLLL: 'YYYY年M月D日(dddd)',
      },
    },
  },
  /* Tenants */
  tenants: {
    1: {
      name: 'nisekocentral',
      title: 'Niseko Central',
    },
    6: {
      name: 'kiniseko',
      title: 'Ki Niseko',
    },
    7: {
      name: 'skye',
      title: 'Skye Niseko',
    },
  },
  /* Tenants Block ID */
  NC_BLOCK_ID,
  KN_BLOCK_ID,
  SN_BLOCK_ID,
  /* Contact email address for tenants */
  contacts: {
    email: {
      kiniseko: 'concierge@kiniseko.com',
      nisekocentral: 'guestservices@nisekocentral.com',
      skye: 'concierge@skyeniseko.com',
    },
  },
  ownersContacts: {
    email: {
      kiniseko: 'owners@kiniseko.com',
      nisekocentral: 'owners@nisekocentral.com',
      skye: 'owners@skyeniseko.com',
    },
  },
  /* External links that are used in the system */
  links: {
    kiniseko: {
      taxDocuments: {
        japaneseTaxInfo: 'https://www.kiniseko.com/uploads/documents/20160920-Japanese-Tax-Information_Tohmatsu.pdf',
        corporateTaxSchedule: 'https://www.kiniseko.com/uploads/documents/HTM-Corporate-Tax-Schedule_NEW.pdf',
        individualTaxSchedule: 'https://www.kiniseko.com/uploads/documents/HTM-Individual-Tax-Schedule_NEW.pdf',
        ownerTaxQuestionnaire: 'https://www.kiniseko.com/uploads/documents/HTM_Owner_Tax_Questionnaire_20180522.pdf',
      },
      downloadAllTaxDocuments: 'https://www.kiniseko.com/uploads/documents/Other-tax-document_ALL.pdf',
      chargeableWorkRate: 'https://www.kiniseko.com/uploads/documents/HTM-Rates-for-Chargeable-Work-_NEW.pdf',
      paymentRequirement: 'https://www.htmniseko.com/uploads/owners/KN-Owner-Fund-Transfer-20200805.pdf',
    },
    nisekocentral: {
      taxDocuments: {
        japaneseTaxInfo: 'https://www.nisekocentral.com/uploads/docs/20160913-Japanese-Tax-Information_Tohmatsu.pdf',
        corporateTaxSchedule: 'https://www.nisekocentral.com/uploads/docs/HTM-Corporate-Tax-Schedule_NEW.pdf',
        individualTaxSchedule: 'https://www.nisekocentral.com/uploads/docs/HTM-Individual-Tax-Schedule_NEW.pdf',
        ownerTaxQuestionnaire: 'https://www.nisekocentral.com/uploads/docs/HTM_Owner_Tax_Questionnaire_20180522.pdf',
      },
      downloadAllTaxDocuments: 'https://www.nisekocentral.com/uploads/docs/Other-tax-document_ALL.pdf',
      chargeableWorkRate: 'https://www.nisekocentral.com/uploads/docs/HTM-Rates-for-Chargeable-Work-_NEW.pdf',
      paymentRequirement: 'https://www.htmniseko.com/uploads/owners/NC-Owner-Fund-Transfer-20200805.pdf',
    },
    skye: {
      taxDocuments: {
        japaneseTaxInfo: 'https://assets.skyeniseko.com/documents/20141112_Japanese_Tax-Information_NAS.pdf',
        corporateTaxSchedule: 'https://assets.skyeniseko.com/documents/HTM-Corporate-Tax-Schedule_NEW.pdf',
        individualTaxSchedule: 'https://assets.skyeniseko.com/documents/HTM-Individual-Tax-Schedule_NEW.pdf',
        ownerTaxQuestionnaire: 'https://assets.skyeniseko.com/documents/HTM_Owner_Tax_Questionnaire_20180522.pdf',
      },
      downloadAllTaxDocuments: 'https://assets.skyeniseko.com/documents/Other-tax-document_ALL.pdf',
      chargeableWorkRate: 'https://assets.skyeniseko.com/documents/HTM-Rates-for-Chargeable-Work-_NEW.pdf',
      paymentRequirement: 'https://www.htmniseko.com/uploads/owners/SN-Owner-Fund-Transfer-20200805.pdf',
    },
  },
  twoFANoteSymbol: '*',
  idleTimeout: 20 * 60 * 1000, // 20 minutes in milliseconds
  LogRocketDev: 'xjipyp/owner-portal-bkyg1',
  LogRocketProd: 'n5hc39/owner-portal',
};
