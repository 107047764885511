import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'redux/modules/router';
import { hide } from 'redux/modules/popup';
import { Divider, Header, Label, Portal, Segment, Transition } from 'semantic-ui-react';

export class Popup extends Component {
  static propTypes = {
    popup: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  handleClick(actionLink) {
    this.props.push(actionLink);
    this.props.hide();
  }

  render() {
    const { isOpen, title, text, actionText, actionLink, headerColor, showActionButton } = this.props.popup;

    const baseStyle = {
      top: '1%',
      left: '50%',
      position: 'fixed',
      transform: 'translateX(-50%)',
      textAlign: 'center',
      minWidth: '300px',
      zIndex: 1000,
    };
    const headerStyle = {
      padding: '10px 5px 0px',
      color: headerColor || '#005486',
    };
    const subheaderStyle = {
      padding: '5px',
      color: '#464646',
    };
    const buttonStyle = {
      color: '#005486',
      border: 'none',
      fontSize: '13px',
    };
    const closeButtonStyle = {
      color: '#9a9a9a',
      border: 'none',
      fontSize: '13px',
    };

    return (
      <Transition visible={isOpen} animation="fade" duration={2000}>
        <Portal open={isOpen}>
          <Segment style={baseStyle}>
            <Header style={headerStyle}>
              {title}
              <Header.Subheader style={subheaderStyle}>{text}</Header.Subheader>
            </Header>
            <Divider />
            {
              showActionButton && (
                <Label as="a" basic style={buttonStyle} onClick={() => this.handleClick(actionLink)}>
                  {actionText}
                </Label>
              )
            }
            <Label as="a" basic style={closeButtonStyle} onClick={() => this.props.hide()}>
              <FormattedMessage id="general.close" />
            </Label>
          </Segment>
        </Portal>
      </Transition>
    );
  }
}

export default connect(state => ({ popup: state.popup }), { hide, push })(Popup);
