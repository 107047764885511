import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { NisekoCentral, KiNiseko, SkyeNiseko } from 'components';
import ContactUsWidget from 'components/Widget/ContactUsWidget';
import { Container } from 'semantic-ui-react';

export class UsefulInfo extends PureComponent {
  static propTypes = {
    tenant: PropTypes.oneOf(['kiniseko', 'nisekocentral', 'skye']).isRequired,
    intl: PropTypes.object.isRequired,
  };

  render() {
    const { tenant, intl: { formatMessage } } = this.props;

    return (
      <>
        <Helmet title={formatMessage({ id: `page.general.info.title.${tenant}` })} />
        <Container>
          { tenant === 'nisekocentral' && <NisekoCentral /> }
          { tenant === 'kiniseko' && <KiNiseko /> }
          { tenant === 'skye' && <SkyeNiseko /> }
          <ContactUsWidget />
        </Container>
      </>
    );
  }
}

export default injectIntl(UsefulInfo);
