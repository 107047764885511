import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon, Step } from 'semantic-ui-react';

export default class StepWrapper extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    steps: PropTypes.array.isRequired,
  };

  render() {
    const { onClick, steps } = this.props;
    return (
      <Step.Group widths={steps.length} style={{ width: '100%' }} size="mini">
        {
          steps.map((step, index) => (
            <Step key={step.title} completed={step.completed} active={step.active} disabled={step.disabled} onClick={() => onClick(index)}>
              <Icon name={step.icon} />
              <Step.Content>
                <Step.Title>{step.title}</Step.Title>
              </Step.Content>
            </Step>
          ))
        }
      </Step.Group>
    );
  }
}
