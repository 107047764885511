import types from './types';

const { LOADING, LOADED, LOADER_DISPLAY } = types;

const initialState = {
  loading: false,
  loaderVisible: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADER_DISPLAY:
      return {
        ...state,
        // Display the loader only if an operation is still in progress
        loaderVisible: state.loading,
      };
    case LOADED:
      return {
        ...state,
        loading: false,
        loaderVisible: false,
      };
    default:
      return state;
  }
}
