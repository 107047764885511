import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Label } from 'semantic-ui-react';

const COLOR = { true: 'green', false: 'red' };

export default class ToggleField extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    input: PropTypes.object,
    text: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isActive: props.isActive,
    };
  }

  handleClick(isActive) {
    const { onBlur } = this.props.input;
    // Redux field callback
    onBlur(!isActive);
    // Toggle value
    this.setState({ isActive: !isActive });
  }

  render() {
    const { text } = this.props;
    const { isActive } = this.state;
    const style = { width: '80px', padding: '9px', textAlign: 'center', textTransform: 'capitalize', display: 'block', cursor: 'pointer' };
    const color = COLOR[isActive];
    const displayText = isActive ? text[0] : text[1];
    return (
      <Label size="medium" color={color} style={style} onClick={() => this.handleClick(isActive)}>
        {displayText}
      </Label>
    );
  }
}
