import { setLoaded, setLoading, displayLoader } from './actions';
import activationTypes from '../activation/types';
import authTypes from '../auth/types';
import balanceTypes from '../balance/types';
import bookingTypes from '../booking/types';
import contractTypes from '../contract/types';
import historyTypes from '../history/types';
import paymentTypes from '../payment/types';
import dialogTypes from '../dialog/types';

export default ({ dispatch }) => next => action => {
  switch (action.type) {
    case activationTypes.CHANGE_PASSWORD:
    case activationTypes.FORGOT_PASSWORD:
    case activationTypes.RESET_PASSWORD:
    case activationTypes.VERIFY:
    case authTypes.LOAD_OWNERS:
    case authTypes.LOGIN:
    case authTypes.LOGOUT:
    case authTypes.RESEND:
    case authTypes.SEND_REQUEST:
    case authTypes.VERIFY:
    case balanceTypes.LOAD:
    case bookingTypes.LOAD:
    case bookingTypes.LOAD_OWNER_BOOKINGS:
    case bookingTypes.LOAD_ROOM_BOOKINGS:
    case contractTypes.LOAD:
    case historyTypes.LOAD:
    case paymentTypes.LOAD:
      dispatch(setLoading());
      // Display the loader only if an operation is still in progress after 2 seconds
      setTimeout(() => {
        dispatch(displayLoader());
      }, 2000);
      break;
    case activationTypes.CHANGE_PASSWORD_FAIL:
    case activationTypes.CHANGE_PASSWORD_SUCCESS:
    case activationTypes.FORGOT_PASSWORD_FAIL:
    case activationTypes.FORGOT_PASSWORD_SUCCESS:
    case activationTypes.RESET_PASSWORD_FAIL:
    case activationTypes.RESET_PASSWORD_SUCCESS:
    case activationTypes.VERIFY_FAIL:
    case activationTypes.VERIFY_SUCCESS:
    case authTypes.AUTH_CALLBACK:
    case authTypes.LOAD_OWNERS_FAIL:
    case authTypes.LOAD_OWNERS_SUCCESS:
    case authTypes.LOGIN_FAIL:
    case authTypes.LOGIN_SUCCESS:
    case authTypes.LOGOUT_FAIL:
    case authTypes.LOGOUT_SUCCESS:
    case authTypes.RESEND_FAIL:
    case authTypes.RESEND_SUCCESS:
    case authTypes.SEND_REQUEST_FAIL:
    case authTypes.SEND_REQUEST_SUCCESS:
    case authTypes.VERIFY_FAIL:
    case authTypes.VERIFY_SUCCESS:
    case balanceTypes.LOAD_FAIL:
    case balanceTypes.LOAD_SUCCESS:
    case bookingTypes.LOAD_FAIL:
    case bookingTypes.LOAD_SUCCESS:
    case bookingTypes.LOAD_OWNER_BOOKINGS_SUCCESS:
    case bookingTypes.LOAD_ROOM_BOOKINGS_SUCCESS:
    case contractTypes.LOAD_FAIL:
    case contractTypes.LOAD_SUCCESS:
    case historyTypes.LOAD_FAIL:
    case historyTypes.LOAD_SUCCESS:
    case paymentTypes.LOAD_FAIL:
    case paymentTypes.LOAD_SUCCESS:
    case dialogTypes.DISPLAY: // Make sure the loader is not displayed when a dialog is displayed
      dispatch(setLoaded());
      break;
    default:
      break;
  }
  next(action);
};
