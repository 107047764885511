import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { push } from 'redux/modules/router';
import { FormattedMessage } from 'react-intl';
import { Header, Icon } from 'semantic-ui-react';

const REDIRECT_LINK = '/selectOwnerId';

export class UserStatus extends PureComponent {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    closeSidebar: PropTypes.func,
    float: PropTypes.string,
    inverted: PropTypes.bool,
    logout: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    theme: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    float: 'right',
    inverted: false,
  };

  handleLogout() {
    const { closeSidebar, logout } = this.props;
    // Close mobile menu sidebar when user logout
    if (typeof closeSidebar === 'function') {
      closeSidebar();
    }
    logout();
  }

  get isAdmin() {
    const { admin } = this.props.auth;
    return (admin && typeof admin === 'object' && Object.keys(admin).length > 0);
  }

  get usernameStyle() {
    const { inverted, theme } = this.props;
    return {
      verticalAlign: 'top',
      margin: '10px',
      display: 'inline-block',
      color: !inverted ? theme?.page?.logout?.user : undefined,
    };
  }

  get logoutStyle() {
    const { inverted, theme } = this.props;
    return {
      verticalAlign: 'top',
      margin: '10px',
      display: 'inline-block',
      cursor: 'pointer',
      color: !inverted ? theme?.page?.logout?.button : undefined,
    };
  }

  render() {
    const { float, inverted, name, push } = this.props;

    return (
      <span style={{ float, textAlign: float, margin: '20px' }}>
        <Header as="h5" inverted={inverted} style={this.usernameStyle}>
          <Icon name="user" />
          <Header.Content>
            { name }
          </Header.Content>
        </Header>
        <a role="presentation" onClick={() => this.handleLogout()} style={this.logoutStyle}>
          <FormattedMessage id="page.login.action.logout" />
        </a>
        {
          this.isAdmin && (
            <a role="presentation" onClick={() => push(REDIRECT_LINK)} style={{ display: 'block', margin: '10px' }}>
              <FormattedMessage id="page.login.action.selectOwner" />
            </a>
          )
        }
      </span>
    );
  }
}

export default connect(state => ({ auth: state.auth, theme: state.tenant.theme }), { push })(UserStatus);
