import types from './types';

const { LOADING, LOADED, LOADER_DISPLAY } = types;

export function displayLoader() {
  return { type: LOADER_DISPLAY };
}

export function setLoading() {
  return { type: LOADING };
}

export function setLoaded() {
  return { type: LOADED };
}
