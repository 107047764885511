import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const CurrentStatus = loadable(() => import(/* webpackChunkName: "ComingSoon" */ './CurrentStatus'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncCurrentStatus = ({ route }) => (
  <CurrentStatus route={route} />
);

asyncCurrentStatus.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncCurrentStatus;
