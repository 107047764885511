import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatNumber, getTenantCode } from 'utils/misc';
import { Container, Header } from 'semantic-ui-react';
import { Table } from 'components';

const HEADERS = [
  { id: 'roomCategory', content: <FormattedMessage id="page.occupancy.label.roomCategory" /> },
  { id: 'avgOccNights', content: <FormattedMessage id="page.occupancy.label.avgOccNights" /> },
  { id: 'categoryRate', content: <FormattedMessage id="page.occupancy.label.categoryRate" /> },
];

export default class YearToDateOccupancy extends Component {
  static propTypes = {
    statistics: PropTypes.object.isRequired,
  };

  get title() {
    const { id, pastStatsStartDate, pastStatsEndDate } = this.props.statistics;

    return (
      <Header as="h2">
        <FormattedMessage id={`tenant.${getTenantCode(id)}.name`} />
        &nbsp;
        <FormattedMessage id="page.occupancy.label.yearToDate.title" />
        <Header.Subheader>
          <FormattedMessage
            id="page.occupancy.label.yearToDate.subtitle"
            values={{ start: moment(pastStatsStartDate).format('l'), end: moment(pastStatsEndDate).format('l') }}
          />
        </Header.Subheader>
      </Header>
    );
  }

  get body() {
    const { roomCategories } = this.props.statistics;

    return Array.isArray(roomCategories) ? roomCategories.map(cat => ({
      id: cat.name,
      content: [
        cat.name,
        cat.avgOccNights,
        formatNumber(cat.rate, { style: 'currency', precision: 0 }),
      ],
    })) : [];
  }

  render() {
    return (
      <Container style={{ padding: '20px' }}>
        { this.title }
        <Table headers={HEADERS} body={this.body} />
      </Container>
    );
  }
}
