const types = {
  LOAD: 'app/booking/LOAD',
  LOAD_SUCCESS: 'app/booking/LOAD_SUCCESS',
  LOAD_OWNER_BOOKINGS: 'app/booking/LOAD_OWNER_BOOKINGS',
  LOAD_OWNER_BOOKINGS_SUCCESS: 'app/booking/LOAD_OWNER_BOOKINGS_SUCCESS',
  LOAD_ROOM_BOOKINGS: 'app/booking/LOAD_ROOM_BOOKINGS',
  LOAD_ROOM_BOOKINGS_SUCCESS: 'app/booking/LOAD_ROOM_BOOKINGS_SUCCESS',
  LOAD_FAIL: 'app/booking/LOAD_FAIL',
  RESET: 'app/booking/RESET',
};

export default types;
