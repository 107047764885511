import types from './types';

const {
  VERIFY, VERIFY_SUCCESS, VERIFY_FAIL,
  CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL,
  RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL,
  FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAIL,
  CLEAN_UP,
} = types;

const initialState = {
  changing: false,
  changed: false,
  resetting: false,
  resetComplete: false,
  verifying: false,
  verified: false,
  token: null,
  error: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return {
        ...state,
        changing: true,
        changed: false,
        error: {},
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changing: false,
        changed: true,
      };
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        changing: false,
        error: action.error,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        resetting: true,
        resetComplete: false,
        error: {},
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetting: false,
        resetComplete: true,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetting: false,
        error: action.error,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        resetting: true,
        resetComplete: false,
        error: {},
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        resetting: false,
        resetComplete: true,
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        resetting: false,
        error: action.error,
      };
    case VERIFY:
      return {
        ...state,
        verifying: true,
        verified: false,
        token: null,
        error: {},
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        verifying: false,
        verified: true,
        token: action.result.id,
      };
    case VERIFY_FAIL:
      return {
        ...state,
        verifying: false,
        error: action.error,
      };
    case CLEAN_UP:
      return { ...initialState };
    default:
      return state;
  }
}
