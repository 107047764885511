const types = {
  CREATE: 'app/bankAccount/CREATE',
  CREATE_SUCCESS: 'app/bankAccount/CREATE_SUCCESS',
  CREATE_FAIL: 'app/bankAccount/CREATE_FAIL',
  LOAD: 'app/bankAccount/LOAD',
  LOAD_SUCCESS: 'app/bankAccount/LOAD_SUCCESS',
  LOAD_FAIL: 'app/bankAccount/LOAD_FAIL',
  RESET: 'app/bankAccount/RESET',
  UPDATE: 'app/bankAccount/UPDATE',
  UPDATE_SUCCESS: 'app/bankAccount/UPDATE_SUCCESS',
  UPDATE_FAIL: 'app/bankAccount/UPDATE_FAIL',
};

export default types;
