import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const Booking = loadable(() => import(/* webpackChunkName: "Booking" */ './Booking'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncBooking = ({ route }) => (
  <Booking route={route} />
);

asyncBooking.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncBooking;
