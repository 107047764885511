import cookies from './cookies';

export function setCookie(name, value, { expireAt, ttl }) {
  const cookieBites = [];
  cookieBites.push(`${name}=${value}`);
  cookieBites.push(`domain=${window.location.hostname}`);
  cookieBites.push('path=/');

  if (expireAt) {
    const cookieExpires = new Date(expireAt);
    cookieBites.push(`expires=${cookieExpires.toUTCString()}`);
  }
  if (ttl) {
    cookieBites.push(`max-age=${ttl}`);
  }

  document.cookie = cookieBites.join(';');
}

export function setAuthCookie(loginResponse) {
  const { id, ttl } = loginResponse;
  setCookie(cookies.AuthToken, id, { ttl });
}

export function setTrustedDeviceCookie(trustedDevice) {
  const { id, expireAt } = trustedDevice;
  setCookie(cookies.TrustedDevice, id, { expireAt });
}
