import queryTransformer from '@htmniseko/htm-helpers/api/queryTransformer';

// Conversion rules that are used to convert into API URL
const CONVERTERS = {
  ownerId: (key, value) => `filter[where][ownerId]=${value}`,
  include: (key, value) => `filter[include]=${value}`,
  qRooms: (key, value) => `includes[${key}]=${value}`,
  startDate: (key, value) => `filter[where][and][1][start][gte]=${decodeURIComponent(value)}`,
  endDate: (key, value) => `filter[where][and][2][start][lte]=${decodeURIComponent(value)}`,
  status: (key, value) => `filter[where][and][3][status]=${value}`,
};

const transformQuery = queryTransformer(CONVERTERS);

export default function transform(params, locale) {
  // Transform all the current API query parameters to HTM API query format
  const queries = transformQuery(params, locale);
  // Remove ALL empty values ("", null, undefined and 0)
  const cleanedQueries = queries.filter(q => q);
  return cleanedQueries.join('&');
}
