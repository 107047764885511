import types from './types';

const {
  RESET,
  LOAD, LOAD_SUCCESS, LOAD_FAIL,
} = types;

export function clearStore() {
  return { type: RESET };
}

export function loadHistory(ownerId, token) {
  const params = { ownerId };

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get('/owners/findHistories', { params, token }),
  };
}
