import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const LoginPage = loadable(() => import(/* webpackChunkName: "LoginPage" */ './LoginPage'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncLoginPage = ({ route }) => (
  <LoginPage route={route} />
);

asyncLoginPage.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncLoginPage;
