import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import RenderRoute from './RenderRoute';

const ChildRoutes = ({ configs, isAdmin, isUser, locale, routes, tenantId, userAllowedTenants }) => {
  if (!routes) {
    return <>no routes found</>;
  }

  return (
    <Switch>
      {
        routes.map((route, i) => (
          <Route
            key={route.key || i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={({ location }) => (
              <RenderRoute
                configs={configs}
                isAdmin={isAdmin}
                isUser={isUser}
                locale={locale}
                location={location}
                route={route}
                tenantId={tenantId}
                userAllowedTenants={userAllowedTenants}
              />
            )}
          />
        ))
      }
    </Switch>
  );
};

ChildRoutes.propTypes = {
  configs: PropTypes.object,
  isAdmin: PropTypes.bool,
  isUser: PropTypes.bool,
  locale: PropTypes.string,
  routes: PropTypes.array.isRequired,
  tenantId: PropTypes.string,
  userAllowedTenants: PropTypes.arrayOf(PropTypes.number),
};

ChildRoutes.defaultProps = {
  configs: {},
  isAdmin: false,
  isUser: false,
  locale: 'en',
  userAllowedTenants: [],
};

export default ChildRoutes;
