import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { push } from 'redux/modules/router';
import { Header, Tab, Menu } from 'semantic-ui-react';
import { getMenu } from 'components/Navigation';

const NavigationBar = () => {
  const user = useSelector(state => state.auth.user);
  const admin = useSelector(state => state.auth.admin);
  const pathname = useSelector(state => state.router.location.pathname);
  const screenWidth = useSelector(state => state.client.screenWidth);
  const tenantTheme = useSelector(state => state.tenant.theme);
  const tenantId = useSelector(state => state.tenant.id);
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const { maxWidth, colors: tenantColors } = tenantTheme;

  const marginLeft = `${((screenWidth - maxWidth) / 2) + 23}px`;

  // TODO For now, we will have to control Tab's activeIndex manually with the information we get from react-router
  // Long-term solution, might be to upgrade to RRv4. With that, basically every component can have a route, so we could potentially utilize that
  const filterPanes = (panes, acls) => {
    const userAcls = acls || [];
    // Copy panes and filter the children
    const panesCopy = panes.map(pane => {
      const filteredChildren = pane.children ? filterPanes(pane.children, userAcls) : [];
      return { ...pane, children: filteredChildren };
    });
    // Then filter the main panes & return all panes that are accessible by user's ACL
    return panesCopy.filter(pane => !pane.acl || pane.acl.some(level => userAcls.includes(level)));
  };

  const renderSubPanes = pane => (
    <Tab.Pane style={{ color: '#FFFFFF', backgroundColor: tenantColors.primary }} attached="bottom">
      {
          // Render title only if there is no sub panes given
          pane.children.length === 0 ?
            (
              <Header
                inverted
                as="span"
                size="medium"
                style={{ marginLeft }}
              >
                {pane.title}
              </Header>
            )
            : pane.children.map((sub, index) => sub.title && (
              <Header
                inverted
                key={sub.title}
                as="span"
                size="medium"
                onClick={() => sub.url && dispatch(push(sub.url))}
                style={{ marginLeft: index === 0 ? marginLeft : '20px', cursor: 'pointer', fontWeight: pathname.endsWith(sub.url) ? 'bold' : 'normal' }}
              >
                {sub.title}
              </Header>
            ))
        }
    </Tab.Pane>
  );

  const renderPanes = panes => panes.map((pane, index) => ({
    menuItem: (
      <Menu.Item
        key={pane.title}
        name={pane.title}
        position={pane.position}
        onClick={() => dispatch(push(pane.url))}
        style={{ marginLeft: index === 0 ? marginLeft : '' }}
      />
    ),
    render: () => renderSubPanes(pane),
  }));

  const panes = getMenu(formatMessage, user, admin, tenantId);
  const userPanes = filterPanes(panes, user && user.acl);

  const activeIndex = panes.findIndex(p => {
    if (p.children) {
      return p.children.find(subPane => pathname.includes(subPane.url));
    }
    return pathname.endsWith(p.url);
  });

  return (
    <Tab
      menu={{ color: 'black', inverted: true, borderless: true, pointing: true, attached: 'top', size: 'massive' }}
      panes={renderPanes(userPanes)}
      activeIndex={activeIndex}
    />
  );
};

export default NavigationBar;
