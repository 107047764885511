import superagent from 'superagent';
import transform from './ApiQueryTransformer';
import config from '../config';

const methods = ['get', 'post', 'put', 'patch', 'delete'];

function formatUrl(path) {
  // TODO This could use some cleaning up
  let adjustedPath = (path[0] !== '/') ? `/${path}` : path;
  adjustedPath = `/v0${adjustedPath}`;

  if (__SERVER__) {
    // Prepend host and port of the API server to the path.
    return config.api.host + adjustedPath;
  }

  // Prepend `/api` to relative URL, to proxy to API server.
  return `/api${adjustedPath}`;
}

export default class ApiClient {
  constructor({ locale, token } = {}) {
    this.locale = locale;
    this.token = token;

    methods.forEach(method => {
      this[method] = (path, { params, data, tenant, token } = {}) => new Promise((resolve, reject) => {
        const request = superagent[method](formatUrl(path));
        request.set('Content-Type', 'application/json');

        if (params) {
          request.query(transform(params, this.locale));
        }

        if (tenant) {
          request.set('X-Tenant', tenant);
        }

        if (this.locale) {
          request.set('Accept-Language', this.locale);
        }

        if (this.token && !token) {
          request.set('Authorization', this.token);
        }

        if (token) {
          request.set('Authorization', token);
        }

        if (data) {
          request.send(data);
        }

        request.end((err, { body } = {}) => (err ? reject(body || err) : resolve(body)));
      });
    });
  }

  configure({ locale }) {
    this.locale = locale;
  }

  setToken(token) {
    this.token = token;
  }
}
