const CLEAR_CHARS_LENGTH = 4;

function getClearStartIndex(value) {
  if (value.length - 2 > CLEAR_CHARS_LENGTH) {
    return value.length - CLEAR_CHARS_LENGTH;
  }
  return Math.round((value.length + 1) / (CLEAR_CHARS_LENGTH / 2));
}

export default function obfuscate(value = '') {
  const clearStartIdx = getClearStartIndex(value);
  const result = value.split('').map((char, index) => (index < clearStartIdx ? '*' : char));
  return result.join('');
}
