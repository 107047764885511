import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Header } from 'semantic-ui-react';

@connect(state => ({ primary: state.tenant.theme.colors.primary }))
export default class ThemeHeader extends Component {
  static propTypes = {
    as: PropTypes.string,
    primary: PropTypes.string.isRequired,
    lineColor: PropTypes.string,
    titleId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    as: 'h2',
  };

  render() {
    const { as, lineColor, titleId, primary } = this.props;
    return (
      <Header
        as={as}
        style={{ borderBottom: '1px #e6e6e6 solid', lineHeight: '50px', marginTop: '10px' }}
      >
        <span style={{ borderBottom: `4px ${lineColor || primary} solid`, padding: '10px' }}>
          <FormattedMessage id={titleId} />
        </span>
      </Header>
    );
  }
}
