import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { isInclusivelyBeforeDay } from 'react-dates';
import momentJpn from '@htmniseko/htm-helpers/time/momentJpn';
import { DatePickerController, close, setDates, setFocus } from '@htmniseko/htm-ui/DatePicker';
import './DatePicker.css';

export class DatePicker extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    datepicker: PropTypes.object.isRequired,
    setDates: PropTypes.func.isRequired,
    setFocus: PropTypes.func.isRequired,
  };

  componentDidUpdate() {
    const { open, focusedInput, startDate, endDate } = this.props.datepicker;
    // Close mobile datepicker after both dates are selected
    if (open && startDate && endDate && focusedInput === 'startDate') {
      this.props.close();
    }
  }

  onDatesChange({ startDate, endDate }) {
    const { datepicker } = this.props;
    // Dates coming from the date picker are set to middle of the day (12 hour)
    //   We want start date to be at 0-hour and end date to be at end of the day
    //   This way we can rely on these dates to load data from APIs

    this.props.setDates({
      startDate: momentJpn(startDate).startOf('day'),
      // Reset endDate when user reselect the dates
      endDate: (endDate === datepicker.endDate || datepicker.focusedInput === 'startDate') ? null : momentJpn(endDate).endOf('day'),
    }, datepicker.disableMaxNights);
  }

  onFocusChange(focus) {
    this.props.setFocus(!focus ? 'startDate' : 'endDate');
  }

  render() {
    const { startDate, endDate, focusedInput } = this.props.datepicker;
    const start = startDate ? momentJpn(startDate) : null;
    const end = endDate ? momentJpn(endDate) : null;

    return (
      <DatePickerController
        enableOutsideDays
        endDate={end}
        firstDayOfWeek={1}
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel
        numberOfMonths={1}
        startDate={start}
        isOutsideRange={day => isInclusivelyBeforeDay(day, moment())}
        onDatesChange={({ startDate, endDate }) => this.onDatesChange({ startDate, endDate })}
        onFocusChange={focusedInput => this.onFocusChange(focusedInput)}
      />
    );
  }
}

export default connect(state => ({ datepicker: state.datepicker }), { close, setDates, setFocus })(DatePicker);
