import isEmpty from 'lodash/isEmpty';
import { getTenants } from 'utils/misc';
import { ROUTE_PATH as BANK_ACCOUNTS_ROUTE_PATH, TITLE_ID as BANK_ACCOUNTS_TITLE_ID } from 'facades/User/BankAccounts/constants';

export default (formatMessage, user, admin, tenantId) => {
  const isSkyePortal = tenantId && tenantId === 'skye';
  const tenantIds = user ? getTenants(user.contracts) : [];
  const isAdmin = !isEmpty(admin);
  // This is different depending on the available child menu items, that depends on the properties the owner owned.
  let firstChildUrl = '';
  if (tenantIds.includes(6)) {
    firstChildUrl = '/general/kiniseko';
  }
  else if (tenantIds.includes(1)) {
    firstChildUrl = '/general/nisekocentral';
  }
  else if (tenantIds.includes(7)) {
    firstChildUrl = '/general/skye';
  }

  return [
    {
      title: formatMessage({ id: 'page.financial.title' }),
      url: '/financial/currentStatus',
      children: [
        { title: formatMessage({ id: 'page.financial.currentStatus.title' }), url: '/financial/currentStatus' },
        { title: formatMessage({ id: 'page.financial.payment.title' }), url: '/financial/payment' },
        { title: formatMessage({ id: 'page.financial.historicalStatements.title' }), url: '/financial/historicalStatements' },
      ],
    },
    // Hide occupancy page from Skye Niseko portal
    !isSkyePortal && {
      title: formatMessage({ id: 'page.occupancy.title' }),
      url: '/occupancy',
    },
    {
      title: formatMessage({ id: 'page.user.myDetails.title' }),
      url: '/user/me',
      children: [
        { title: formatMessage({ id: 'page.user.myDetails.title' }), url: '/user/me' },
        { title: formatMessage({ id: BANK_ACCOUNTS_TITLE_ID }), url: BANK_ACCOUNTS_ROUTE_PATH },
        isAdmin ? '' : { title: formatMessage({ id: 'page.user.changePassword.title' }), url: '/user/changePassword' },
      ].filter(Boolean),
    },
    {
      title: formatMessage({ id: 'page.booking.title' }),
      url: '/booking/existing',
      children: [
        { title: formatMessage({ id: 'page.booking.title' }), url: '/booking/existing' },
        { title: formatMessage({ id: 'page.requestBooking.title' }), url: '/booking/request' },
      ],
    },
    (firstChildUrl !== '') && {
      title: formatMessage({ id: 'page.general.title' }),
      url: firstChildUrl, // This is different depending on the available tabs below, that depends on the properties the owner owned.
      children: [
        tenantIds.includes(6) && { title: formatMessage({ id: 'page.general.info.title.kiniseko' }), url: '/general/kiniseko' },
        tenantIds.includes(1) && { title: formatMessage({ id: 'page.general.info.title.nisekocentral' }), url: '/general/nisekocentral' },
        tenantIds.includes(7) && { title: formatMessage({ id: 'page.general.info.title.skye' }), url: '/general/skye' },
        { title: formatMessage({ id: 'page.contactUs.title' }), url: '/general/contactUs' },
      ].filter(Boolean),
    },
    {
      title: formatMessage({ id: 'page.requestHistory.shortTitle' }),
      url: '/history',
    },
  ].filter(Boolean);
};
