import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Divider, Image, Menu, Sidebar, Segment } from 'semantic-ui-react';
import { logout } from 'redux/modules/auth';
import { push } from 'redux/modules/router';
import { UserStatus } from 'components';
import { getMenu } from 'components/Navigation';

const MobileSidebar = ({ closeSidebar, visible }) => {
  const user = useSelector(state => state.auth.user);
  const admin = useSelector(state => state.auth.admin);
  const screenType = useSelector(state => state.client.screenType);
  const tenantId = useSelector(state => state.tenant.id);
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const handleClick = url => {
    dispatch(push(url));
    dispatch(closeSidebar());
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const filterPanes = (panes, acls) => {
    const userAcls = acls || [];
    // Copy panes and filter the children
    const panesCopy = panes.map(pane => {
      const filteredChildren = pane.children ? filterPanes(pane.children, userAcls) : [];
      return { ...pane, children: filteredChildren };
    });
    // Then filter the main panes & return all panes that are accessible by user's ACL
    return panesCopy
      .filter(pane => !pane.acl || pane.acl.some(level => userAcls.includes(level)))
      .filter(pane => !pane.view || pane.view.some(v => v.includes(screenType)));
  };

  const panes = getMenu(formatMessage, user, admin, tenantId);
  const userPanes = filterPanes(panes, user && user.acl);

  const renderChildren = child => (
    <Menu.Item key={`children-${child.title}`} onClick={() => handleClick(child.url)} style={{ paddingLeft: '50px', fontWeight: 'bold' }}>
      {child.title}
    </Menu.Item>
  );

  const renderMenu = pane => {
    // Render one menu item
    if (pane.children.length <= 1) {
      return (
        <Menu.Item key={pane.title} onClick={() => handleClick(pane.url)} style={{ paddingLeft: '30px', fontWeight: 'bold' }}>
          {pane.title}
        </Menu.Item>
      );
    }

    // Render menu with children.
    const menu = [
      <Menu.Item key={pane.title} style={{ paddingLeft: '30px', fontWeight: 'normal' }}>{pane.title}</Menu.Item>,
    ];
    // Add each of the rendered children into the menu
    pane.children.forEach(sub => menu.push(renderChildren(sub)));
    return menu;
  };

  return (
    <Sidebar as={Segment} animation="overlay" visible={visible} icon="labeled" vertical inverted>
      <Menu secondary vertical inverted style={{ width: '100%' }}>
        <Image src="/logo.png" alt="logo" style={{ maxWidth: '200px', maxHeight: '100px', margin: '10px 30px' }} />
        { user && <Divider /> }
        {
          // Only show menu items when there is an user
          user && userPanes.map(pane => renderMenu(pane))
        }
        { user && <Divider /> }
        { user && <UserStatus float="left" inverted name={user.username} logout={() => handleLogout()} closeSidebar={closeSidebar} /> }
      </Menu>
    </Sidebar>
  );
};

MobileSidebar.propTypes = {
  closeSidebar: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default MobileSidebar;
