import orderBy from 'lodash/orderBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { clearStore, loadHistory } from 'redux/modules/history';
import { formatNumber } from 'utils/misc';
import { NoDataFound, Table } from 'components';

export class RequestHistory extends PureComponent {
  static propTypes = {
    authStore: PropTypes.object.isRequired,
    clearStore: PropTypes.func.isRequired,
    historyStore: PropTypes.object.isRequired,
    loadHistory: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { authStore: { user, token }, loadHistory } = this.props;
    loadHistory(user.id, token);
  }

  componentWillUnmount() {
    // Clean up redux store when unmounting the tab
    this.props.clearStore();
  }

  renderTable() {
    const { historyStore: { histories }, intl: { formatMessage } } = this.props;

    const headers = [
      { id: 'date', width: 2, content: <FormattedMessage id="page.requestHistory.label.date" /> },
      { id: 'type', width: 2, content: <FormattedMessage id="page.requestHistory.label.type" /> },
      { id: 'content', width: 12, content: <FormattedMessage id="page.requestHistory.label.content" /> },
    ];

    // Show most recent history record first.
    const sortedHistories = orderBy(histories, 'created', 'desc');
    const body = sortedHistories.map(history => ({
      id: history.id,
      content: [
        moment(history.created).format('l'),
        formatMessage({ id: `page.requestHistory.type.${history.type}` }),
        this.renderContent(history.type, history.content),
      ],
    }));

    return <Table headers={headers} body={body} />;
  }

  renderNoHistories() {
    const { formatMessage } = this.props.intl;
    return (
      <NoDataFound
        iconColor="brown"
        iconName="folder open outline"
        message={formatMessage({ id: 'page.requestHistory.label.noHistories' })}
      />
    );
  }

  renderContent(type, content) {
    const { formatMessage } = this.props.intl;
    const getMessage = key => formatMessage({ id: `page.user.myDetails.label.${key}` });

    if (type === 'information') {
      const changes = Object.keys(content).map(key => ` ${content[key]} (${getMessage(key)})`);
      return formatMessage({ id: 'page.requestHistory.content.information' }, { changes });
    }

    if (type === 'booking') {
      return `
        ${formatMessage({ id: 'page.requestHistory.content.booking' })}:
        ${content.room};
        ${formatMessage({ id: 'form.label.checkIn' })}: ${content.stay.from},
        ${formatMessage({ id: 'form.label.checkOut' })}: ${content.stay.to};
        ${content.pax.adults} ${formatMessage({ id: 'general.adults' }).toLowerCase()},
        ${content.pax.children} ${formatMessage({ id: 'general.children' }).toLowerCase()},
        ${content.pax.infants} ${formatMessage({ id: 'general.infants' }).toLowerCase()}
      `;
    }

    if (type === 'payment') {
      return `
        ${formatMessage({ id: 'page.requestHistory.content.payment' })}:
        ${content.properties.map(p => `${p.code}, ${formatNumber(p.amount, { style: 'currency', precision: 0 })}`).join('; ')},
        ${content.type.toLowerCase()}
      `;
    }

    if (type === 'contactUs') {
      let message = `Subject: ${content.subject}`;
      if (content.rooms.length > 0) {
        message += `; Rooms: ${content.rooms.map(r => r.code).join(', ')}`;
      }
      return message;
    }

    return '-';
  }

  render() {
    const { loading, loaded, histories } = this.props.historyStore;
    const isLoaded = !loading && loaded;
    const hasHistories = Array.isArray(histories) && histories.length > 0;

    return (
      <>
        { isLoaded && !hasHistories && this.renderNoHistories() }
        { isLoaded && hasHistories && this.renderTable() }
      </>
    );
  }
}

const withIntl = injectIntl(RequestHistory);

export default connect(state => ({ authStore: state.auth, historyStore: state.history }), { clearStore, loadHistory })(withIntl);
