import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { SemanticMaskLoader } from '@htmniseko/htm-ui/SemanticMaskLoader';

const ComingSoon = loadable(() => import(/* webpackChunkName: "ComingSoon" */ './ComingSoon'), {
  fallback: <SemanticMaskLoader visible />,
});

const asyncComingSoon = ({ route }) => (
  <ComingSoon route={route} />
);

asyncComingSoon.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncComingSoon;
