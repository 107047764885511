import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dimmer, Loader as SemanticLoader, Segment } from 'semantic-ui-react';

export default class Loader extends PureComponent {
  static propTypes = {
    messageId: PropTypes.string,
  };

  static defaultProps = {
    messageId: 'general.loading',
  };

  render() {
    const { messageId } = this.props;

    return (
      <Segment
        basic
        padded="very"
        style={{ top: 0, right: 0, bottom: 0, left: 0, position: 'fixed', zIndex: 10000 }}
      >
        <Dimmer active inverted>
          <SemanticLoader active size="large">
            <FormattedMessage id={messageId} />
          </SemanticLoader>
        </Dimmer>
      </Segment>
    );
  }
}
