import types from './types';

const { DISPLAY, HIDE } = types;

const initialState = {
  title: null,
  text: null,
  actionText: null,
  actionLink: null,
  headerColor: null,
  showActionButton: true,
  isOpen: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DISPLAY:
      return {
        title: action.title,
        text: action.text,
        actionText: action.actionText,
        actionLink: action.actionLink,
        headerColor: action.headerColor,
        showActionButton: action.showActionButton,
        isOpen: true,
      };
    case HIDE:
      return initialState;
    default:
      return state;
  }
}
