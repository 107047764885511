import types from './types';

const {
  CREATE, CREATE_SUCCESS, CREATE_FAIL,
  LOAD, LOAD_SUCCESS, LOAD_FAIL,
  UPDATE, UPDATE_SUCCESS, UPDATE_FAIL,
  RESET,
} = types;

const initialState = {
  loading: false,
  loaded: false,
  bankAccounts: [],
  error: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
        bankAccounts: [],
        error: {},
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        bankAccounts: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case CREATE:
    case UPDATE:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
      };
    case CREATE_SUCCESS: {
      // Set the update result in the bank accounts state array and make sure it's correctly sorted (1, 2, 3)
      const bankAccounts = [...state.bankAccounts, action.result].sort((a, b) => (a.ownerNumber - b.ownerNumber));
      return {
        ...state,
        loading: false,
        loaded: true,
        bankAccounts,
      };
    }
    case UPDATE_SUCCESS: {
      // Set the update result in the bank accounts state array
      const bankAccounts = state.bankAccounts.map(bankAcc => (
        bankAcc.id === action.result.id ? action.result : bankAcc
      ));
      return {
        ...state,
        loading: false,
        loaded: true,
        bankAccounts,
      };
    }
    case CREATE_FAIL:
    case UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case RESET:
      return { ...initialState };
    default:
      return state;
  }
}
